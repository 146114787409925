import React from 'react'
import { Table } from 'react-bootstrap'
import sanitizeCNPJ from '../../utils/sanitizeCNPJ'

export default function OrdersTableVert({ data, fundName, className }) {
  return (
    <Table className={`mt-4 orders-table ${className}`} responsive size="md">
      <tbody>
        <tr>
          <th className="text-red">CNPJ Fundo</th>
          <td className="br-gray">{sanitizeCNPJ(data?.fundId) || 'N/A'}</td>
          <th className="text-red">Razão Social</th>
          <td>{fundName || 'N/A'}</td>
        </tr>
        <tr>
          <th className="text-red">Tipo movimentação</th>
          <td className="br-gray">{data?.orderType || 'N/A'}</td>
          <th className="text-red">Valor bruto</th>
          <td>{data?.grossAmount || 'N/A'}</td>
        </tr>
        <tr>
          <th className="text-red">Data da movimentação</th>
          <td className="br-gray">{data?.orderDate || 'N/A'}</td>
          <th className="text-red">IR</th>
          <td>{data?.incomeTax || 'N/A'}</td>
        </tr>
        <tr>
          <th className="text-red">Código da ordem</th>
          <td className="br-gray">{data?.certId || 'N/A'}</td>
          <th className="text-red">IOF</th>
          <td>{data?.financialOperationTax || 'N/A'}</td>
        </tr>
        <tr>
          <th className="text-red">Nota da aplicação</th>
          <td className="br-gray">{data?.subscriptionId || 'N/A'}</td>
          <th className="text-red">Valor Líquido</th>
          <td>{data?.netAmount || 'N/A'}</td>
        </tr>
        <tr>
          <th className="text-red">Data da cotização</th>
          <td className="br-gray">{data?.shareDate || 'N/A'}</td>
          <th className="text-red">Rendimento Bruto</th>
          <td>{data?.grossIncome || 'N/A'}</td>
        </tr>
        <tr>
          <th className="text-red">Quantidade de cotas</th>
          <td className="br-gray">{data?.sharesQuantity || 'N/A'}</td>
          <th className="text-red">Código da movimentação</th>
          <td>{data?.movementCode || 'N/A'}</td>
        </tr>
        <tr>
          <th className="text-red">Valor da cota</th>
          <td className="br-gray">{data?.sharesAmount || 'N/A'}</td>
          <th className="text-red">Informações Adicionais</th>
          <td>{data?.additionalInfo || 'N/A'}</td>
        </tr>
      </tbody>
    </Table>
  )
}
