import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import sanitizeCNPJ from '../../utils/sanitizeCNPJ'

export default function FundsInfoTable({ data, setPortability }) {
  const userToken = window.sessionStorage.getItem('token')
  const fundCnpj = data?.consolidatedBalances?.[0]?.fundId
  const [fundData, setFundData] = useState(null)

  useEffect(() => {
    const cnpj = fundCnpj.replace(/\.|\/|-/g, '')
    const headers = { Authorization: `Bearer ${userToken}` }
    axios
      .get(`${process.env.REACT_APP_RTM_CORE_URL}/api/funds/${cnpj}`, { headers })
      .then((res) => {
        setPortability((values) => {
          return { ...values, adminId: res?.data?.providers?.admin?.cnpjAdmin }
        })
        setFundData(res.data)
      })
      .catch((err) => console.log(err))
  }, [userToken, fundCnpj, setPortability])

  return (
    <Table className="my-4 orders-table" size="md">
      <thead>
        <tr>
          <th className="text-red">CNPJ do Fundo</th>
          <th className="text-red">Razão Social</th>
          <th className="text-red">CNPJ Administrador</th>
          <th className="text-red">Razão Social Administrador</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{fundData?.cnpjFundo}</td>
          <td>{fundData?.nomeFantasia}</td>
          <td>{sanitizeCNPJ(fundData?.providers?.admin?.cnpjAdmin)}</td>
          <td>{fundData?.providers?.admin?.adminName}</td>
        </tr>
      </tbody>
    </Table>
  )
}
