import { RedocStandalone } from 'redoc'
import jsonFile from '../docs/apiSchema.json'

const DevPortal = () => {
  return (
    <RedocStandalone
      spec={jsonFile}
      options={{
        expandResponses: '200',
        noAutoAuth: true,
        theme: {
          colors: { primary: { main: '#c53048' } },
          rightPanel: { backgroundColor: '#691926' },
          logo: { maxHeight: '96px', maxWidth: '200px', gutter: '10px' },
        },
      }}
    />
  )
}

export default DevPortal
