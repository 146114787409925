import React from "react";
import Table from "react-bootstrap/Table";

const TableDashboard = ({ metrics, title, fundMetrics }) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return (
    metrics && (
      <div className="table-dashboard">
        {title}
        <Table>
          <thead className="thead-dashboard">
            <tr>
              <th></th>
              <th>Qtde</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody className="tbody-dashboard">
            <tr>
              <td>Aplicação</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.subscriptionsOperations}</td>
                  <td>{formatter.format(fundMetrics.subscriptionsAmount)}</td>
                </>
              ) : (
                <>
                  <td>{metrics.subscriptionsOperations}</td>
                  <td>{formatter.format(metrics.subscriptionsAmount)}</td>
                </>
              )}
            </tr>
            <tr>
              <td>Resgate Parcial</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.partialRedemptionsOperations}</td>
                  <td>
                    {formatter.format(fundMetrics.partialRedemptionsAmount)}
                  </td>
                </>
              ) : (
                <>
                  <td>{metrics.partialRedemptionsOperations}</td>
                  <td>{formatter.format(metrics.partialRedemptionsAmount)}</td>
                </>
              )}
            </tr>
            <tr>
              <td>Resgate Total</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.fullRedemptionsOperations}</td>
                  <td>{formatter.format(fundMetrics.fullRedemptionsAmount)}</td>
                </>
              ) : (
                <>
                  <td>{metrics.fullRedemptionsOperations}</td>
                  <td>{formatter.format(metrics.fullRedemptionsAmount)}</td>
                </>
              )}
            </tr>
            <tr>
              <td>Resgate</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.redemptionsOperations}</td>
                  <td>{formatter.format(fundMetrics.redemptionsAmount)}</td>
                </>
              ) : (
                <>
                  <td>{metrics.redemptionsOperations}</td>
                  <td>{formatter.format(metrics.redemptionsAmount)}</td>
                </>
              )}
            </tr>
            <tr>
              <td>Total Operações</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.totalOperations}</td>
                  <td>{formatter.format(fundMetrics.totalAmount)}</td>
                </>
              ) : (
                <>
                  <td>{metrics.totalOperations}</td>
                  <td>{formatter.format(metrics.totalAmount)}</td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
      </div>
    )
  );
};

export default TableDashboard;
