const sanitizeCNPJ = (string) => {
  try {
    if (!string.includes('.')) {
      let result = ''
      if (string) {
        result =
          string.slice(0, 2) +
          '.' +
          string.slice(2, 5) +
          '.' +
          string.slice(5, 8) +
          '/' +
          string.slice(8, 12) +
          '-' +
          string.slice(12)
      }
      return result
    }
  } catch (error) {
    return string
  }
}

export default sanitizeCNPJ
