import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import Subscription from './subscription'
import SubscriptionBulkFile from './subscriptionBulkFile'
import Login from './login'
import Index from './mainIndex'
import Operacoes from './operacoes'
import Confirmed from './subscriptionConfirmed'
import DevPortal from './devPortal'
import Dashboard from './dashboard'
import { createContext, useContext } from 'react'
import decodeUserInfo from '../utils/decodeUserInfo'

import GlobalContext from '../context'
import MessageToast from '../components/MessageToast'
import Portability from './portability'
import PortabilityDashboard from './portabilityDashboard'
import PortabilityCheck from './portabilityCheck'
import PortabilityUpload from './portabilityUpload'

const Webpages = () => {
  return (
    <ProvideAuth>
      <GlobalContext>
        <Router>
          <Switch>
            <PrivateRoute path="/orders/register">
              <Subscription />
            </PrivateRoute>
            <PrivateRoute exact path="/orders/bulk">
              <SubscriptionBulkFile />
            </PrivateRoute>
            <Route exact path="/login">
              <Login />
            </Route>
            <PrivateRoute exact path="/">
              <Index />
            </PrivateRoute>
            <PrivateRoute exact path="/portability">
              <Portability />
            </PrivateRoute>
            <PrivateRoute exact path="/portability/upload">
              <PortabilityUpload />
            </PrivateRoute>
            <PrivateRoute exact path="/portability/dashboard">
              <PortabilityDashboard />
            </PrivateRoute>
            <PrivateRoute exact path="/portability/check">
              <PortabilityCheck />
            </PrivateRoute>
            <PrivateRoute exact path="/orders">
              <Operacoes />
            </PrivateRoute>
            <PrivateRoute exact path="/subscription/confirmed">
              <Confirmed />
            </PrivateRoute>
            <Route exact path="/dev-portal">
              <DevPortal />
            </Route>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>

          <MessageToast />
        </Router>
      </GlobalContext>
    </ProvideAuth>
  )
}

const authContext = createContext()

function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export function useAuth() {
  return useContext(authContext)
}

function useProvideAuth() {
  const auth = decodeUserInfo(window.sessionStorage.getItem('token'))
  return auth
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.error ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              fromUrl: location,
              state: { showErrorModal: true, error: auth.error },
            }}
          />
        )
      }
    />
  )
}

export default Webpages
