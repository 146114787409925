import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import RtmLogo from '../../assets/RTM-Elements_logo.svg'
import RtmSearchButton from '../../assets/RTM-Elements_busca.svg'
import { Navbar, Nav } from 'react-bootstrap'

const NavbarLogin = (props) => {
  return (
    <div className="row">
      <div className="col-sm-12 no-gutters">
        <div className="navbar-red"></div>
        <Navbar className="shadow navbar-login">
          <Navbar.Brand href="/index">
            <img id="rtm-logo-login" src={RtmLogo} alt="Logo Institucional" />
          </Navbar.Brand>
          <Nav>
            <Nav.Link className="px-3">Participantes</Nav.Link>
            <Nav.Link className="px-3" href="/dev-portal">
              Portal do Desenvolvedor
            </Nav.Link>
            <Nav.Link className="px-3">Acesse a Plataforma</Nav.Link>
            <Navbar.Brand>
              <img
                className="rtm-search"
                src={RtmSearchButton}
                alt="Imagem do botão de Pesquisa"
              />
            </Navbar.Brand>
          </Nav>
        </Navbar>
      </div>
    </div>
  )
}

export default NavbarLogin
