const message = (value, listMessage = [], message, type = "info") => {
  const cloneListMessage = [...listMessage];
  cloneListMessage.push({
    text: value,
    type: type,
  });

  message(cloneListMessage);
};

export default message;
