import axios from "axios";
import jwtDecode from "jwt-decode";

export const CORE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_RTM_CORE_URL_DEV
    : process.env.REACT_APP_RTM_CORE_URL_UAT;

const instance = axios.create({
  baseUrl: CORE_URL,
});

axios.interceptors.request.use(
  function (config) {
    const user = jwtDecode(sessionStorage.getItem("token"));
    if (Date.now() >= user.exp * 1000) {
      sessionStorage.clear();
      window.location = "/";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
