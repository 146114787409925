import styled, { keyframes } from "styled-components";

const getBackgroundByType = (type) => {
  switch (type) {
    case "warning":
      return "#fff3cd";
    case "error":
      return "#f8d7da";
    case "success":
      return "#d4edda";
    case "info":
      return "#cce5ff";
    default:
      return "#cce5ff";
  }
};

const getColorByType = (type) => {
  switch (type) {
    case "warning":
      return "#856404";
    case "info":
      return "#004085";
    case "error":
      return "#721c24";
    case "success":
      return "#155724";
    default:
      return "#004085";
  }
};

const animateIn = keyframes`
	from{
		transform: translateX(200px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
`;

export const Container = styled.div`
  display: flex;
  width: 400px;
  height: auto;
  position: fixed;
  right: 30px;
  bottom: 30px;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: flex-end;
  z-index: 10;
`;

export const MessageClose = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  transform: rotate(45deg);
  cursor: pointer;

  &::after,
  &::before {
    content: "";
    width: 15px;
    height: 2px;
    display: block;
    position: absolute;
    transform-origin: left center;
    left: 50%;
    top: 50%;
  }

  &::before {
    transform: translateX(-50%);
  }

  &::after {
    transform: rotate(90deg) translate(-50%);
  }
`;

export const Message = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  background: ${(props) => getBackgroundByType(props.type)};
  color: ${(props) => getColorByType(props.type)};
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${(props) => getColorByType(props.type)};
  margin-bottom: 10px;
  animation: ${animateIn} 0.4s ease forwards;
  opacity: 0;

  &:first-child {
    margin-bottom: 0;
  }

  ${MessageClose} {
    &::after,
    &::before {
      background: ${(props) => getColorByType(props.type)};
    }
  }
`;

export const MessageText = styled.span`
  font-size: 1rem;
`;
