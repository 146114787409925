import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import RtmLogo from '../assets/RTM-Elements_logo.svg'
import { Link } from 'react-router-dom'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import decodeUserInfo from '../utils/decodeUserInfo'
import NotificationsAlert from './NotificationsAlert'

function cleanCookies() {
  window.sessionStorage.clear()
  window.location.href = '/'
}

const NavbarRtm = () => {
  const userToken = window.sessionStorage.getItem('token')
  const { user } = decodeUserInfo(userToken)

  return (
    <div className="teste">
      <div className="navbar-red"></div>
      <Navbar className="shadow">
        <div className="d-flex align-items-baseline">
          <Navbar.Brand>
            <Link to="/">
              <img className="rtm-logo" src={RtmLogo} alt="Logo Institucional" />
            </Link>
          </Navbar.Brand>
          <NotificationsAlert user={user} />
        </div>
        <Nav>
          <Nav.Item className="px-3 pt-2 nav-links">
            <Link to="/">Home</Link>
          </Nav.Item>
          <NavDropdown className="px-3" title="Portabilidade" id="basic-nav-dropdown">
            <NavDropdown.Item href="/portability" className="text-center">
              Solicitação
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/portability/upload" className="text-center">
              Upload de Arquivos
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/portability/dashboard" className="text-center">
              Consulta
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown className="px-3" title="Operações" id="basic-nav-dropdown" href="/orders/">
            <NavDropdown.Item href="/orders/register">Aplicação - Resgate</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/orders/bulk">Upload Arquivo</NavDropdown.Item>
          </NavDropdown>
          <Nav.Item className="px-3 pt-2 nav-links">
            <Link to="/orders">Consulta</Link>
          </Nav.Item>
          <Nav.Item className="px-3 pt-2 nav-links">
            <Link to="/dashboard">Dashboard</Link>
          </Nav.Item>

          <NavDropdown
            className="pl-3 navitem-noborder"
            title={user.company || user.contactName}
            id="basic-nav-dropdown">
            <NavDropdown.Item href="#" onClick={cleanCookies}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    </div>
  )
}

export default NavbarRtm
