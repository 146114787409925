import React from 'react'
import { Table } from 'react-bootstrap'

export default function PositionsTable({ data }) {
  const positions = data?.consolidatedBalances

  return (
    <Table className="my-4 orders-table" responsive size="md">
      <thead>
        <tr>
          <th className="text-red">Posição #</th>
          <th className="text-red">Nota</th>
          <th className="text-red">Data movimentação</th>
          <th className="text-red">Aplicação original</th>
          <th className="text-red">Data da cota</th>
          <th className="text-red">Quantidade de cotas</th>
          <th className="text-red">Valor da cota</th>
          <th className="text-red">Valor bruto</th>
          <th className="text-red">IR</th>
          <th className="text-red">IOF</th>
          <th className="text-red">Valor Líquido</th>
          <th className="text-red">Idade Nota</th>
          <th className="text-red">Data último IR</th>
          <th className="text-red">Valor cota último IR</th>
        </tr>
      </thead>
      <tbody>
        {positions.map((position, index) => (
          <tr key={'position' + index}>
            <td>{index + 1}</td>
            <td>{position?.certId}</td>
            <td>{position?.movmentDate}</td>
            <td>{position?.originalSubscription}</td>
            <td>{position?.sharesDate}</td>
            <td>{position?.sharesQuantity}</td>
            <td>{position?.sharesAmount}</td>
            <td>{position?.grossAmount}</td>
            <td>{position?.incomeTax}</td>
            <td>{position?.financialOperationTax}</td>
            <td>{position?.netAmount}</td>
            <td>{position?.certAge}</td>
            <td>{position?.lastIncomeTaxDate}</td>
            <td>{position?.lastIncomeTaxAmount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
