import React from 'react'
import { useState } from 'react'
import sanitizeCNPJ from '../utils/sanitizeCNPJ'
import ModalOrders from './ModalOrders'
import { CgList } from 'react-icons/cg'

const TableBody = ({ order, orderType, i }) => {
  const [modalState, setModalState] = useState(false)

  const sanitizeDate = (UTCDate) => {
    const date = new Date(Date.parse(UTCDate))
    const hrs = date.getHours()
    const mins = String(date.getMinutes()).padStart(2, '0')
    return `${date.toLocaleDateString()}, ${hrs}:${mins} `
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  const openModal = () => {
    setModalState(true)
  }

  const closeModal = () => {
    setModalState(false)
  }

  const fundCnpj = order.MltplOrdrDtls.IndvOrdrDtls.FinInstrmDtls.Id
  const fundName = order.MltplOrdrDtls.IndvOrdrDtls.FinInstrmDtls.Nm
  const fundId = order.MltplOrdrDtls.BnfcryDtls.Id
  const orderDateTime = order.MltplOrdrDtls.OrdrDtTm
  const amount =
    order.MltplOrdrDtls.IndvOrdrDtls.AmtOrUnitsOrPctg?.GrssAmt ||
    order.MltplOrdrDtls.IndvOrdrDtls.AmtOrUnits?.GrssAmt
  const addInfo = order.MltplOrdrDtls.IndvOrdrDtls.AdditionalDetails

  const [orderStatus, setOrderStatus] = useState(addInfo.OrderStatus)
  const updateOrderStatus = (status) => {
    setOrderStatus(status)
  }

  return (
    <>
      <tr className="table-body">
        <td className="center-data">
          <CgList className="details-link" onClick={openModal} />
        </td>
        <td className="td border-right">{fundId}</td>
        <td className="td border-right">
          {fundName}
          <span className="details-sub">CNPJ: {sanitizeCNPJ(fundCnpj)}</span>
        </td>
        <td className="td border-right">{formatter.format(amount.replace(',', '.'))}</td>
        <td className={`td border-right text-left status-${orderStatus.toLowerCase()}`}>
          {orderStatus.toUpperCase()}
        </td>
        <td className="td d-flex align-items-center">{sanitizeDate(orderDateTime)}</td>
      </tr>
      <ModalOrders
        updateOrderStatus={updateOrderStatus}
        modalState={modalState}
        closeModal={closeModal}
        order={order}
        index={i + 1}
      />
    </>
  )
}

export default TableBody
