import React, { useState } from 'react'
import NavbarRtm from '../components/NavbarRtm'
import Card from 'react-bootstrap/Card'
import RtmBackground from '../assets/RTM-Elements_back-element.svg'
import CardFormBulkFile from '../components/CardFormBulkFile'

const SubscriptionBulkFile = () => {
  const [orderType, setOrderType] = useState('Upload de Arquivo')

  const setOrderToValue = (value) => {
    switch (value) {
      case 'subscription':
        setOrderType('Upload Aplicações')
        break
      case 'redemption':
        setOrderType('Upload Resgates')
        break
      default:
        setOrderType('Upload de Arquivo')
        break
    }
  }

  return (
    <div id="card-bulk-file">
      <NavbarRtm />
      <div className="row">
        <div className="offset-sm-7 col-sm-5">
          <Card.Title>{orderType}</Card.Title>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <CardFormBulkFile changeCardTitle={setOrderToValue} />
        </div>
      </div>
      <div className="row">
        <div className="offset-sm-8 col-sm-4">
          <img
            className="rtm-background"
            src={RtmBackground}
            alt="Imagem personalizada para o Background"
          />
        </div>
      </div>
      <div className="col-sm-12 rtm-background-gray"></div>
    </div>
  )
}
export default SubscriptionBulkFile
