import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { BsFillPlusSquareFill } from 'react-icons/bs'
import { RiEditBoxFill } from 'react-icons/ri'
import decodeUserInfo from '../../utils/decodeUserInfo'
import sanitizeCNPJ from '../../utils/sanitizeCNPJ'

export default function PositionsTableVert({
  data,
  className,
  investorId,
  setInvestorId,
  portability,
}) {
  const userToken = window.sessionStorage.getItem('token')
  const { user } = decodeUserInfo(userToken)
  const toggleInput = () => {
    document.querySelector('.table-wrapper').classList.toggle('bg-opacity')
    document.querySelector('.show-input-adm').classList.toggle('d-none')
  }

  return (
    <>
      <div className="show-input-adm text-center d-none fade-in">
        <input
          type="text"
          name="investorAdminIdToParty"
          id="investorAdminIdToParty"
          className="shadow-lg"
          placeholder="Identificação do cliente no Administrador (Cessionário)"
          value={investorId}
          onChange={(e) => {
            setInvestorId(e.target.value)
          }}
        />
        <Button onClick={() => toggleInput()}>OK</Button>
      </div>
      <div className="table-wrapper">
        <Table className={`mt-4 orders-table ${className}`} responsive size="md">
          <tbody>
            <tr>
              <th className="text-red">
                Id. Investidor no ADM <br /> (Cedente)
              </th>
              <td className="br-gray">{data?.investorAdminIdFromParty || 'N/A'}</td>
              <th className="text-red">
                Id. Investidor no ADM <br />
                (Cessionario)
              </th>
              <td>
                {data?.investorAdminIdToParty ? (
                  data.investorAdminIdToParty
                ) : portability?.toParty === user.cnpj ? (
                  investorId ? (
                    <div className="d-flex align-items-center">
                      {investorId}
                      <RiEditBoxFill
                        color={'#c53048'}
                        size={'1.4rem'}
                        className="add-admin-id ml-3"
                        onClick={() => {
                          toggleInput()
                        }}
                      />
                    </div>
                  ) : (
                    <BsFillPlusSquareFill
                      color={'#c53048'}
                      size={'1.4rem'}
                      className="add-admin-id"
                      onClick={() => {
                        toggleInput()
                      }}
                    />
                  )
                ) : null}
              </td>
            </tr>
            <tr>
              <th className="text-red">CNPJ Fundo</th>
              <td className="br-gray">{sanitizeCNPJ(data?.fundId) || 'N/A'}</td>
              <th className="text-red">Razão Social</th>
              <td>{data?.fundName || 'N/A'}</td>
            </tr>
            <tr>
              <th className="text-red">Nota</th>
              <td className="br-gray">{data?.certId || 'N/A'}</td>
              <th className="text-red">Aplicação original</th>
              <td>{data?.originalSubscription || 'N/A'}</td>
            </tr>
            <tr>
              <th className="text-red">Data movimentação</th>
              <td className="br-gray">{data?.movmentDate || 'N/A'}</td>
              <th className="text-red">Quantidade de cotas</th>
              <td>{data?.sharesQuantity || 'N/A'}</td>
            </tr>
            <tr>
              <th className="text-red">Data da cota</th>
              <td className="br-gray">{data?.sharesDate || 'N/A'}</td>
              <th className="text-red">Valor da cota</th>
              <td>{data?.sharesAmount || 'N/A'}</td>
            </tr>
            <tr>
              <th className="text-red">Valor bruto</th>
              <td className="br-gray">{data?.grossAmount || 'N/A'}</td>
              <th className="text-red">IOF</th>
              <td>{data?.financialOperationTax || 'N/A'}</td>
            </tr>
            <tr>
              <th className="text-red">IR</th>
              <td className="br-gray">{data?.incomeTax || 'N/A'}</td>
              <th className="text-red">Idade Nota</th>
              <td>{data?.certAge || 'N/A'}</td>
            </tr>
            <tr>
              <th className="text-red">Valor Líquido</th>
              <td className="br-gray">{data?.netAmount || 'N/A'}</td>
              <th className="text-red">Data último IR</th>
              <td>{data?.lastIncomeTaxDate || 'N/A'}</td>
            </tr>
            <tr>
              <th className="text-red">Valor cota último IR</th>
              <td className="br-gray">{data?.lastIncomeTaxAmount || 'N/A'}</td>
              <th></th>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}
