import React from 'react'

export default function PortabilityDashboardLeft({ portabilities, handleCurrentPortability }) {
  const stringToDate = (str) => {
    const localeDate = new Date(str).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
    const localeTime = new Date(str).toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    })

    return `${localeDate} - ${localeTime}`
  }

  return (
    <div className="left-dashboard col-4">
      <h4 className="text-gray">Processos em Andamento</h4>
      <hr className="text-red" />
      <div className="dashboard-filter no-min-height">
        <ul className="dashboard-filter-list">
          {portabilities &&
            portabilities
              .filter((portability) => portability.portabilityStatus !== 'FINISH')
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((portability, index) => {
                return (
                  <li
                    className="d-flex justify-content-between"
                    key={`portability${index}`}
                    onClick={(e) => handleCurrentPortability(e, portability)}>
                    <span className="portability-li">
                      <strong>{portability.id}</strong>
                    </span>
                    <span className="portability-li">{stringToDate(portability.created_at)}</span>
                  </li>
                )
              })}
        </ul>
      </div>
      <br />
      <h4 className="text-gray">Portabilidades Encerradas</h4>
      <hr className="text-red" />
      <div className="dashboard-filter no-min-height">
        <ul className="dashboard-filter-list">
          {portabilities &&
            portabilities
              .filter((portability) => portability.portabilityStatus === 'FINISH')
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((portability, index) => {
                return (
                  <li
                    key={`portability${index}`}
                    onClick={(e) => handleCurrentPortability(e, portability)}>
                    {`${portability.id} ${stringToDate(portability.created_at)}`}
                  </li>
                )
              })}
        </ul>
      </div>
    </div>
  )
}
