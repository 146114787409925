import { Table } from 'react-bootstrap'
import TableHeader from './TableHeader'
import TableBody from './TableBody'

const TableOrders = ({ orders, orderType }) => {
  return (
    <div>
      <Table striped hover id="myTable">
        <TableHeader/>
        <tbody>
          {orders.map((order, i) => {
            return (
              <TableBody
                order={order}
                key={`order-${i}`}
                orderType={orderType}
                i={i}
              />
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default TableOrders
