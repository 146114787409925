import React from 'react'

const sanitizeDate = (UTCDate) => {
  const formatDate = UTCDate.split('/').reverse().join('-')
  const date = new Date(formatDate)
  if (UTCDate === 'NaN/NaN/NaN') return 'Não disponível'
  else return `${date.toISOString().slice(0, 10).split('-').reverse().join('/')}`
}

const sanitizeValue = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

const FundInfo = ({ fund }) => {
  return (
    <div className="fund-info">
      <div className="fund-title">Informações do Fundo</div>
      <table id="fund-info-table">
        <tbody>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">CNPJ</td>
            <td className="read-only ml-4">
              <em>{fund.infos.cnpjFundo}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Nome Fantasia</td>
            <td className="read-only ml-4">
              <em>{fund.infos.nomeFantasia}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Classe</td>
            <td className="read-only ml-4">
              <em>{fund.infos.classeAnbima}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Código ANBIMA</td>
            <td className="read-only ml-4">
              <em>{fund.infos.codigoFundo}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Cotização Aplicação</td>
            <td className="read-only ml-4">
              <em>{sanitizeDate(fund.infos.prazo.dataEmissaoCota)}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Liquidação Resgate</td>
            <td className="read-only ml-4">
              <em>{sanitizeDate(fund.infos.prazo.dataPagamentoResgate)}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Cotização Resgate</td>
            <td className="read-only ml-4">
              <em>{sanitizeDate(fund.infos.prazo.dataConversaoResgate)}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Administrador</td>
            <td className="read-only ml-4">
              <em>{fund.infos.providers.admin.adminName}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Custodiante</td>
            <td className="read-only ml-4">
              <em>{fund.infos.providers.custodian.custodianName}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Gestor</td>
            <td className="read-only ml-4">
              <em>{fund.infos.providers.manager.managerName}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-3 text-sm">Aplicação Inicial Mínima</td>
            <td className="read-only ml-4">
              <em>{sanitizeValue(fund.infos.movimentacao.aplicacaoInicialMinima)}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-1 text-sm">Aplicação Adicional Mínima</td>
            <td className="read-only ml-2">
              <em>{sanitizeValue(fund.infos.movimentacao.aplicacaoAdicionalMinima)}</em>
            </td>
          </tr>
          <tr className="d-flex justify-start">
            <td className="input-text mr-4 text-sm">Resgate Mínimo</td>
            <td className="read-only ml-4">
              <em>{sanitizeValue(fund.infos.movimentacao.resgateMinimo)}</em>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="fund-footer">
        <em>powered by ANBIMA Data</em>
      </div>
    </div>
  )
}

export default FundInfo
