import { Container } from 'react-bootstrap'

const Banner = ({ children, iconPosition, size }) => {
  return (
    <div className={`banner banner-${size}`}>
      <Container>
        <div
          className={`d-flex justify-content-between flex-row${
            iconPosition === 'right' ? '-reverse' : false
          }`}
        >
          <h3
            className={`greet banner-text flex-grow-1 text-${
              iconPosition === 'right' ? 'left' : 'right'
            }`}
          >
            {children.slice(1, children.length)}
          </h3>
          <div id="banner-icon">{children[0]}</div>
        </div>
      </Container>
    </div>
  )
}

export default Banner
