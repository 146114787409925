import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { GoCloudUpload } from 'react-icons/go'
import { ClipLoader } from 'react-spinners'

export default function ANBIMAFileUpload({
  setFileName,
  fileName,
  loaderState,
  isLoading,
  postAndShowModal,
  showErrorModal,
  setFileStandard,
}) {
  return (
    <Form className="d-flex flex-column card-bulk">
      <h4 className="text-red">
        <em>Arquivo - ANBIMA</em>
      </h4>
      <Form.Group controlId="balances">
        <Form.Control
          size="lg"
          type="file"
          className="d-none input-file-selector my-4"
          onChange={(e) => {
            let result
            e.target.value.includes('/')
              ? (result = e.target.value.split('/'))
              : (result = e.target.value.split('\\'))
            const length = result.length
            setFileName({ ...fileName, balances: result[length - 1] })
          }}
          required
        />
        <Form.Label className="choose-file-button shadow-md">
          <GoCloudUpload className="mx-2" /> {fileName?.balances || 'Pesquisar'}
        </Form.Label>
        <p className="input-file-label input-text-port input-text mt-3">
          Arquivo de Posições Consolidadas
        </p>
      </Form.Group>
      <Form.Group controlId="orders">
        <Form.Control
          size="lg"
          type="file"
          className="d-none input-file-selector"
          onChange={(e) => {
            let result
            e.target.value.includes('/')
              ? (result = e.target.value.split('/'))
              : (result = e.target.value.split('\\'))
            const length = result.length
            setFileName({ ...fileName, orders: result[length - 1] })
          }}
          required
        />
        <Form.Label className="choose-file-button shadow-md">
          <GoCloudUpload className="mx-2" /> {fileName?.orders || 'Pesquisar'}
        </Form.Label>
        <p className="input-file-label input-text-port input-text mt-3">Arquivo de Movimentações</p>
      </Form.Group>
      <div className="d-flex justify-content-center">
        {isLoading ? (
          <ClipLoader size={40} color="#bf2c4a" />
        ) : (
          <>
            <Button
              variant="secondary"
              className="mr-3"
              onClick={() => {
                setFileStandard(false)
              }}>
              VOLTAR
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                loaderState(true)
                try {
                  postAndShowModal()
                } catch (error) {
                  showErrorModal()
                }
              }}>
              UPLOAD
            </Button>
          </>
        )}
      </div>
    </Form>
  )
}
