import React from 'react'
import NavbarRtm from '../components/NavbarRtm'
import Card from 'react-bootstrap/Card'
import RtmBackground from '../assets/RTM-Elements_back-element.svg'
import PortabilityBulkFile from '../components/portability/PortabilityBulkFile'

const PortabilityUpload = () => {
  return (
    <div id="card-bulk-file">
      <NavbarRtm />
      <div className="row">
        <div className="offset-sm-7 col-sm-5">
          <Card.Title>Portabilidade - Arquivos</Card.Title>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <PortabilityBulkFile />
        </div>
      </div>
      <div className="row">
        <div className="offset-sm-8 col-sm-4">
          <img
            className="rtm-background"
            src={RtmBackground}
            alt="Imagem personalizada para o Background"
          />
        </div>
      </div>
      <div className="col-sm-12 rtm-background-gray"></div>
    </div>
  )
}
export default PortabilityUpload
