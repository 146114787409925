import React, { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import Input from "../components/InputField";
import postRedemption from "../utils/postRedemption";
import postSubscription from "../utils/postSubscription";

const inputTexts = {
  pInvest: "Investidor*",
  pAplica: "Primeira Aplicação",
  pCNPJ: "CNPJ do Fundo*",
  pNameFund: "Nome do Fundo",
  pValueAplicacao: "Valor da Aplicação*",
  pValueResgate: "Valor do Resgate*",
  pDateAplica: "Data da Aplicação",
  pDateResgate: "Data do Resgate",
  pDateCot: "Cotização Aplicação",
  pDateLiq: "Liquidação Resgate",
  pDateCotRes: "Cotização Resgate",
  pTipoConta: "Tipo Conta",
  pTipoLiquidacao: "Tipo Liquidação",
  pBanco: "Banco",
  pConta: "Conta + Digito Verificador",
  pAgencia: "Agência",
};

const sanitizeDate = (UTCDate) => {
  const formatDate = UTCDate.split("/").reverse().join("-");
  const date = new Date(formatDate);
  return `${date.toISOString().slice(0, 10).split("-").reverse().join("/")}`;
};

const sanitizeValue = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

function ModalComponent({
  fund,
  txInfo,
  onModalClose,
  handleSuccess,
  resetForm,
}) {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const initAddVal = fund.movimentacao.aplicacaoInicialMinima;
  const minRed = fund.movimentacao.resgateMinimo;

  const postOrder = async (txInfo, fund) => {
    if (txInfo.transactionType === "subscription") {
      await postSubscription(txInfo, fund);
      resetForm();
    } else {
      await postRedemption(txInfo, fund);
      resetForm();
    }
    onModalClose();
  };

  const validateForm = async () => {
    try {
      if (await handleSuccess()) {
        handleShow();
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <div className="d-flex buttons">
        <Button
          variant="primary text-uppercase"
          onClick={validateForm}
          id="approve"
        >
          Confirmar
        </Button>
        <Button
          variant="secondary text-uppercase"
          id="cancel"
          onClick={resetForm}
        >
          CANCELAR
        </Button>
      </div>
      {fund ? (
        <Modal show={showModal} onHide={handleClose}>
          <div className="row">
            <div className="col-sm-12">
              {txInfo.transactionType === "subscription" ? (
                <div>
                  {txInfo.amount < initAddVal ? (
                    <Alert variant="secondary">
                      <Alert.Heading>
                        Não foi possivel seguir com a operação
                      </Alert.Heading>
                      <hr />
                      <p>
                        O valor inserido é menor que valor mínimo exigido pelo
                        fundo<b> {fund.nomeFantasia}</b>. Inicial Mínimo:{" "}
                        <b>R$ {initAddVal}</b>.
                      </p>
                    </Alert>
                  ) : null}
                  <Modal.Title className="text-center text-white">
                    Confirmar Aplicação
                  </Modal.Title>
                </div>
              ) : (
                <div>
                  {txInfo.amount < minRed ? (
                    <Alert variant="secondary">
                      <Alert.Heading>
                        Não foi possivel seguir com a operação
                      </Alert.Heading>
                      <hr />
                      <p>
                        O valor inserido é menor que valor de resgate mínimo
                        exigido pelofFundo
                        <b> {fund.nomeFantasia}</b>. Resgate Mínimo:{" "}
                        <b>R$ {minRed}</b>.
                      </p>
                    </Alert>
                  ) : null}
                  <Modal.Title className="text-center text-white">
                    Confirmar Resgate
                  </Modal.Title>
                </div>
              )}
            </div>
          </div>
          <Modal.Body>
            <div className="row mx-2">
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={txInfo.investor}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {inputTexts.pInvest}
                </p>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={txInfo.ref}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {inputTexts.pTipoLiquidacao}
                </p>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="string"
                  value={fund.cnpjFundo}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {inputTexts.pCNPJ}
                </p>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={txInfo.cd}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {inputTexts.pTipoConta}
                </p>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={fund.nomeFantasia}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {inputTexts.pNameFund}
                </p>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={txInfo.clr}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {inputTexts.pBanco}
                </p>
              </div>
              <div className="col-sm-6">
                <>
                  <input
                    className="radio-special dark-red"
                    id="rd3"
                    type="radio"
                    name="rd3"
                    readOnly
                    checked
                  />
                  <label className="mr-2" htmlFor="rd3"></label>
                  {txInfo.transactionType === "subscription" ? (
                    <>
                      <span className="form-font-radio dark-red">
                        {txInfo.firstTime === "true" ? "Sim" : "Não"}
                      </span>
                      <p className="input-text input-modal">
                        {inputTexts.pAplica}
                      </p>
                    </>
                  ) : (
                    <>
                      <span className="form-font-radio dark-red">
                        {txInfo.partialRedemption === "true"
                          ? "Resgate Parcial"
                          : "Resgate Total"}
                      </span>{" "}
                      <p className="input-text input-modal">Tipo de Resgate</p>
                    </>
                  )}
                </>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={txInfo.issr}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {inputTexts.pAgencia}
                </p>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={sanitizeValue(txInfo.amount)}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {txInfo.transactionType === "subscription"
                    ? inputTexts.pValueAplicacao
                    : inputTexts.pValueResgate}
                </p>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={txInfo.idd}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {inputTexts.pConta}
                </p>
              </div>
              <div className="col-sm-6">
                <Input
                  className="modal-inputbg dark-red"
                  type="text"
                  value={sanitizeDate(fund.prazo.dataRequisicao)}
                  readOnly
                />
                <p className="input-text mt-1 input-modal">
                  {txInfo.transactionType === "subscription"
                    ? inputTexts.pDateAplica
                    : inputTexts.pDateResgate}
                </p>
              </div>
              {txInfo.transactionType === "subscription" ? (
                <div className="col-sm-6">
                  <Input
                    className="modal-inputbg dark-red"
                    type="text"
                    value={sanitizeDate(fund.prazo.dataEmissaoCota)}
                    readOnly
                  />
                  <p className="input-text mt-1 input-modal">
                    {inputTexts.pDateCot}
                  </p>
                </div>
              ) : (
                <div className="col-sm-6">
                  <Input
                    className="modal-inputbg dark-red"
                    type="text"
                    value={sanitizeDate(fund.prazo.dataPagamentoResgate)}
                    readOnly
                  />
                  <p className="input-text mt-1 input-modal">
                    {inputTexts.pDateLiq}
                  </p>
                </div>
              )}
              <div className="offset-sm-6 col-sm-6">
                <Button
                  className="text-uppercase mx-2"
                  variant="secondary"
                  onClick={handleClose}
                >
                  Fechar
                </Button>
                {(txInfo.transactionType === "subscription" &&
                  txInfo.amount < initAddVal) ||
                (txInfo.transactionType === "redemption" &&
                  txInfo.amount < minRed) ? (
                  <Button
                    className="text-uppercase"
                    variant="primary"
                    disabled
                    onClick={async () => {
                      await postOrder(txInfo, fund);
                    }}
                  >
                    Confirmar
                  </Button>
                ) : (
                  <Button
                    className="text-uppercase"
                    variant="primary"
                    onClick={async () => {
                      await postOrder(txInfo, fund);
                    }}
                  >
                    Confirmar
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center text-center">
              <div className="mt-1">NÃO EXISTEM INFORMAÇÕES SUFICIENTES</div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default ModalComponent;
