import React from 'react'
import InputMask from 'react-input-mask'

const Input = (props) => (
  <InputMask
    className={props.className}
    id={props.id}
    mask={props.mask}
    type={props.type}
    name={props.name}
    placeholder={props.placeholder}
    onChange={props.onChange}
    readOnly={props.readOnly}
    value={props.value}
  />
)

export default Input
