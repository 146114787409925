import React from "react";
import { Button, Container, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Formik } from "formik";
import * as Yup from "yup";
import { authRequest } from "../../utils/auth";
import ErrorField from "../ErrorField";

export default function LoginCard({ props, handleSetError }) {
  const handleLoginForm = async (values) => {
    try {
      await authRequest(values);
      window.location.href = "/";
    } catch (err) {
      handleSetError({
        header: "Erro ao realizar o login",
        message: "Credenciais inválidas. Por favor, tente novamente.",
      });
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email inválido")
      .required("Insira um nome de usuário"),
    password: Yup.string().required("Insira uma senha"),
  });

  return (
    <Container>
      <Card className="login-card">
        <Card.Title className="text-center"></Card.Title>
        <Card.Body>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={async (values) => {
              await handleLoginForm(values);
            }}
            validationSchema={validationSchema}
          >
            {({ errors, touched, handleChange, handleSubmit }) => (
              <Form className="pt-4">
                <ErrorField errors={errors} touched={touched} input="email" />
                <Form.Group className="mb-3" controlId="email" name="email">
                  <Form.Control
                    type="email"
                    placeholder="exemplo@exemplo.com"
                    onChange={handleChange("email")}
                  />
                  <Form.Label className="input-text">Email</Form.Label>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="password"
                  name="password"
                >
                  <ErrorField
                    errors={errors}
                    touched={touched}
                    input="password"
                  />
                  <Form.Control
                    type="password"
                    placeholder="Insira sua senha"
                    onChange={handleChange("password")}
                  />
                  <Form.Label className="input-text">Senha</Form.Label>
                </Form.Group>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    className="d-flex align-items-center justify-content-center"
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                </div>
                <div className="d-flex justify-content-end">
                  <Button variant="link" type="link" id="forgot-pass">
                    ESQUECI MINHA SENHA
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
}
