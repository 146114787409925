import React from 'react'
import { Container, Form } from 'react-bootstrap'
import NavbarRtm from '../components/NavbarRtm'
import decodeUserInfo from '../utils/decodeUserInfo'
import { useState } from 'react'
import OrderPortabilityForm from '../components/portability/OrderPortabilityForm'
import PositionForm from '../components/portability/PositionForm'
import PortabilityForm from '../components/portability/PortabilityForm'
import { useHistory } from 'react-router'

export default function Portability() {
  const userToken = window.sessionStorage.getItem('token')
  const { user } = decodeUserInfo(userToken)
  const [portabilityData, setPortabilityData] = useState({
    fromPartyName: user.contactName,
    fromParty: user.cnpj,
  })
  const [investorData, setInvestorData] = useState({})
  const [balanceData, setBalanceData] = useState([])
  const [orderData, setOrderData] = useState([])
  const [formStep, setFormStep] = useState(0)
  const history = useHistory()

  const checkPortabilityData = () => {
    const payload = {
      ...portabilityData,
      investor: investorData,
      consolidatedBalances: balanceData,
      orders: orderData,
    }

    history.push({ pathname: 'portability/check', state: { portability: payload } })
  }

  return (
    <div>
      <NavbarRtm />
      <Container>
        <Form className="m-5" id="portability-form">
          {formStep === 0 && (
            <PortabilityForm
              formStep={formStep}
              setFormStep={setFormStep}
              user={user}
              investorData={investorData}
              setInvestorData={setInvestorData}
              setPortabilityData={setPortabilityData}
              portabilityData={portabilityData}
            />
          )}
          {formStep === 1 && (
            <PositionForm
              setBalanceData={setBalanceData}
              positions={balanceData}
              setFormStep={setFormStep}
              formStep={formStep}
              investorData={investorData}
              user={user}
            />
          )}
          {formStep === 2 && (
            <OrderPortabilityForm
              balanceData={balanceData}
              investorData={investorData}
              formStep={formStep}
              handleFormStep={setFormStep}
              orderData={orderData}
              setOrderData={setOrderData}
              user={user}
              checkPortabilityData={checkPortabilityData}
            />
          )}
        </Form>
      </Container>
    </div>
  )
}
