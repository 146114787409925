import axios from 'axios'
import { CORE_URL } from './config'

export const getMetrics = async () => {
  const authString = `Bearer ${window.sessionStorage.getItem('token')}`
  const header = {
    headers: {
      Authorization: authString,
    },
  }

  return await axios.get(`${CORE_URL}/api/user/data/orders`, header).then((res) => {
    return res.data
  })
}

export const getFundMetrics = async (cnpj) => {
  const authString = `Bearer ${window.sessionStorage.getItem('token')}`
  const header = {
    headers: {
      Authorization: authString,
    },
  }

  return await axios.get(`${CORE_URL}/api/user/data/fund/${cnpj}`, header).then((res) => {
    return res.data
  })
}
