import React from "react";
import { Modal } from "react-bootstrap";
import TableHeaderBunks from "./TableHeaderBunks";
import TableBodyBunks from "./TableBodyBunks";

const UploadModal = ({ show, closeUploadModal, orders, type }) => {
  return (
    <Modal show={show}>
      <Modal.Header closeButton onClick={() => closeUploadModal(false)}>
        <h2 className="text-danger">
          As seguintes informações foram registradas
        </h2>
      </Modal.Header>
      <Modal.Body>
        <table className="table-bunks">
          <TableHeaderBunks />
          {orders &&
            orders.map((order, i) => {
              return <TableBodyBunks order={order} i={i} key={i} type={type} />;
            })}
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
