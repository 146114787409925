import React from 'react'
import { RiComputerLine } from 'react-icons/ri'
import Banner from '../components/Banner'
import IconTile from '../components/IconTile'
import { GoGraph } from 'react-icons/go'
import { BiCheckCircle, BiTransfer } from 'react-icons/bi'
import { FaCashRegister } from 'react-icons/fa'
import NavbarRtm from '../components/NavbarRtm'
import { useAuth } from './index'

const Index = () => {
  const auth = useAuth()
  const user = auth.user

  return (
    <>
      <NavbarRtm />
      <Banner iconPosition="left" size="lg">
        <RiComputerLine color="#C5C6CB" size="25vh" />
        <span>Bem-vindo(a) {user.name || ''}</span>
        <br />
        <em>
          <span className="greet-sm">{user.company || user.contactName}</span>
        </em>
      </Banner>
      <div className="content">
        <h2 className="content-title">Acesso Rápido</h2>
        <hr className="divider" />
        <div className="tiles">
          <IconTile title="Portabilidade" href="portability">
            <BiTransfer className="dark-red" />
          </IconTile>
          <IconTile title="Operações" href="orders/register">
            <FaCashRegister className="dark-red" />
          </IconTile>
          <IconTile title="Consulta" href="orders">
            <BiCheckCircle size="100" className="dark-red" />
          </IconTile>
          <IconTile title="Dashboard" href="dashboard" className="align-self-stretch">
            <GoGraph size="80" className="dark-red" />
          </IconTile>
        </div>
      </div>
    </>
  )
}
export default Index
