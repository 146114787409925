import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import NavbarRtm from '../components/NavbarRtm'
import { BiErrorAlt } from 'react-icons/bi'
import IdentificationsTable from '../components/portability/IdentificationsTable'
import axios from 'axios'
import PartyIdentificationsTable from '../components/portability/PartyIdentificationsTable'
import OrdersTable from '../components/portability/OrdersTable'
import PositionsTable from '../components/portability/PositionsTable'
import decodeUserInfo from '../utils/decodeUserInfo'
import FundsInfoTable from '../components/portability/FundsInfoTable'

export default function PortabilityCheck() {
  const [portability, setPortability] = useState({})
  const location = useLocation()
  const userToken = window.sessionStorage.getItem('token')
  const { user } = decodeUserInfo(userToken)

  useEffect(() => {
    setPortability(location.state.portability)
  }, [location])

  const sanitizePayload = (payload) => {
    payload?.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (key === 'fundId') {
          obj[key] = obj[key].replace(/\.|\/|-/g, '')
          return obj[key]
        }

        if (obj[key] === '') {
          delete obj[key]
        }

        if (obj[key]?.includes(',')) {
          obj[key] = obj[key].replace(',', '.')
          return obj[key]
        }

        if (obj[key]?.includes('/')) {
          obj[key] = obj[key].split('/').reverse().join('-')
        }
      })
    })
  }

  const submitPortability = async () => {
    try {
      sanitizePayload(portability?.orders)
      sanitizePayload(portability?.consolidatedBalances)
      await axios.post(`${process.env.REACT_APP_MVP_FUNDS}/portability`, portability)
      window.location.href = '/'
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <div>
      <NavbarRtm />
      {portability ? (
        <Container className="py-5 portability-check">
          <h2 className="text-red text-center">Resumo de Solicitação de Portabilidade</h2>
          <div className="portability-check">
            <h5 className="text-gray mt-5">Identificação Partes</h5>
            <PartyIdentificationsTable data={location.state.portability} user={user} />
          </div>
          <div className="portability-check">
            <h5 className="text-gray mt-5">Informações Cotista</h5>
            <small>
              <em>Dados sensíveis compartilhados somente entre Cedente e Cessionário</em>
            </small>
            <IdentificationsTable data={location.state.portability} />
          </div>
          <div className="portability-check">
            <h5 className="text-gray mt-5">Informações do Fundo de Investimento</h5>
            <FundsInfoTable data={location.state.portability} setPortability={setPortability} />
          </div>
          <div className="portability-check">
            <h5 className="text-gray mt-5">Posições Consolidadas</h5>
            <PositionsTable data={location.state.portability} />
          </div>
          <div className="portability-check">
            <h5 className="text-gray mt-5">Movimentações</h5>
            <OrdersTable data={location.state.portability} />
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Button className="btn-block" onClick={() => submitPortability()}>
              Registrar Portabilidade
            </Button>
          </div>
        </Container>
      ) : (
        <Container className="py-5 d-flex flex-column justify-content-center align-items-center">
          <h2 className="text-red text-center">
            <BiErrorAlt /> Algo deu errado
          </h2>

          <h5 className="text-gray text-center my-4">
            Infelizmente identificamos um problema com a comunicação com o servidor. <br />
            Por favor, realize a solicitação de portabilidade novamente.
          </h5>
          <Button className="mt-3">
            <Link to="/portability">Voltar</Link>
          </Button>
        </Container>
      )}
    </div>
  )
}
