import React from 'react'
import Card from 'react-bootstrap/Card'
import BootstrapForm from '../components/BootstrapForm'

const CardForm = ({ onModalClose, changeCardTitle }) => {
  return (
    <Card>
      <Card.Body>
        <BootstrapForm
          onModalClose={onModalClose}
          changeCardTitle={changeCardTitle}
        />
      </Card.Body>
    </Card>
  )
}

export default CardForm
