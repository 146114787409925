import React, { useEffect, useRef, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { AiOutlineCloudDownload } from 'react-icons/ai'

export default function DownloadFileDropDown({ currentPortability }) {
  const [showDropDown, setShowDropDown] = useState(false)
  const fileStandards = ['anbima', 'sinqia']
  const ref = useRef(null)
  useOutsideAlerter(ref)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropDown(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  return (
    <div className="download">
      <p
        className="text-red m-0 download-link"
        onClick={() => {
          setShowDropDown(!showDropDown)
        }}>
        Download de Arquivo <BiChevronDown />
      </p>
      {showDropDown && (
        <div className="drop-down-wrapper" ref={ref}>
          {fileStandards.map((fileStandard) => {
            return (
              <a
                key={`file-${fileStandard}`}
                className="text-red m-0"
                href={`${process.env.REACT_APP_MVP_FUNDS}/portability/download/file/${fileStandard}/${currentPortability.id}`}
                download>
                <AiOutlineCloudDownload className="mr-2" />
                Arquivo {`${fileStandard.toUpperCase()}`}
              </a>
            )
          })}
        </div>
      )}
    </div>
  )
}
