import { useState, useEffect } from "react";
import React from "react";
import NavbarRtm from "../components/NavbarRtm";
import getSubscriptions from "../utils/getSubscriptions";
import getRedemptions from "../utils/getRedemptions";
import Banner from "../components/Banner";
import { FaRegListAlt } from "react-icons/fa";
import { PulseLoader } from "react-spinners";
import { Tab, Tabs } from "react-bootstrap";
import TableOrders from "../components/TableOrders";
import ErrorModal from "../components/ErrorModal";

import DownloadButton from "../components/DownloadButton";

const styledContainer = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "90%",
  margin: "40px auto 10px",
};

const styledInput = {
  width: "350px",
};

const Operacoes = () => {
  const [loading, setLoading] = useState(true);
  const [endpoint, setEndpoint] = useState("subscription");
  const [subscriptions, setSubscriptions] = useState([]);
  const [redemptions, setRedemptions] = useState([]);
  const [error, setError] = useState(null);

  async function fetchData() {
    setError(false);
    try {
      const subscriptionData = await getSubscriptions();
      setSubscriptions(subscriptionData);
    } catch (error) {
      if (!error.response) {
        setError({
          header: "Ocorreu um Erro",
          message:
            "Não conseguimos carregar as informações necessárias, tente novamente mais tarde.",
        });
      } else if (error.response.status === 404) {
        setLoading(false);
      }
    }
    try {
      const redemptionData = await getRedemptions();
      setRedemptions(redemptionData);
    } catch (error) {
      if (!error.response) {
        setError({
          header: "Ocorreu um Erro",
          message:
            "Não conseguimos carregar as informações necessárias, tente novamente mais tarde.",
        });
      } else if (error.response.status === 404) {
        setLoading(false);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function searchTable() {
    let input, filter, tables, tr, td, cell, i;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    tables = document.querySelectorAll("#myTable");
    tables.forEach((table) => {
      tr = table.getElementsByTagName("tr");
      for (i = 1; i < tr.length; i++) {
        // Hide the row initially.
        tr[i].style.display = "none";

        td = tr[i].getElementsByTagName("td");
        for (let j = 0; j < td.length; j++) {
          cell = tr[i].getElementsByTagName("td")[j];
          if (cell) {
            if (cell.innerHTML.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
              break;
            }
          }
        }
      }
    });
  }

  return (
    <>
      <NavbarRtm />
      <Banner iconPosition="left" size="md">
        <FaRegListAlt
          color="#C5C6CB"
          size="30vh"
          className="align-self-center"
        />
        <span>Consulta de Operações</span>
      </Banner>
      {error && (
        <ErrorModal
          show={!!error}
          close={fetchData}
          error={error}
          backHistory={true}
        />
      )}
      <div className="filter-general" style={styledContainer}>
        <input
          type="text"
          id="myInput"
          style={styledInput}
          onKeyUp={searchTable}
          placeholder="Filtrar por CNPJ, Status, etc"
        ></input>
        <DownloadButton endpointSet={endpoint} />
      </div>
      <Tabs
        defaultActiveKey="subscription"
        className="my-4 tabs-orders"
        onSelect={(k) => setEndpoint(k)}
      >
        <Tab eventKey="subscription" title="Aplicações">
          {loading ? (
            <div className="d-flex my-5 justify-content-center text-center">
              <PulseLoader size="30px" color="#c53048" />
            </div>
          ) : subscriptions.length > 0 ? (
            <TableOrders orders={subscriptions} orderType="subscriptions" />
          ) : (
            <div className="d-flex justify-content-center text-center">
              <strong className="without-data">
                Nenhuma aplicação encontrada
              </strong>
            </div>
          )}
        </Tab>
        <Tab eventKey="redemption" title="Resgates">
          {loading ? (
            <div className="d-flex my-5 justify-content-center text-center">
              <PulseLoader size="30px" color="#c53048" />
            </div>
          ) : redemptions.length > 0 ? (
            <TableOrders orders={redemptions} orderType="redemptions" />
          ) : (
            <div className="d-flex justify-content-center text-center">
              <strong className="without-data">
                Nenhum resgate encontrado
              </strong>
            </div>
          )}
        </Tab>
        <Tab eventKey="settlement" title="Liquidação" disabled></Tab>
      </Tabs>
    </>
  );
};
export default Operacoes;
