import React from "react";

const TableHeader = (props) => {
  return (
    <thead className="table-header">
      <tr>
        <th className="border-danger"></th>
        <th className="border-danger">INVESTIDOR</th>
        <th className="border-danger">FUNDO DE INVESTIMENTO</th>
        <th className="border-danger">VALOR</th>
        <th className="border-danger">STATUS</th>
        <th className="border-danger">DATA DA OPERAÇÃO</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
