import React from 'react'
import Button from 'react-bootstrap/Button'
import deleteOrder from '../utils/deleteOrder'

const RejectButton = ({
  userProvider,
  orderId,
  orderType,
  rejectOrder,
  openErrorModal,
  loaderState,
}) => {
  return (
    <Button
      color="primary"
      id="reject-button"
      onClick={async () => {
        try {
          loaderState(true)
          const statusObj = await deleteOrder(userProvider, orderId, orderType)
          return rejectOrder(statusObj)
        } catch (error) {
          console.log('ERRO', error)
          loaderState(false)
          openErrorModal()
        }
      }}
    >
      REJEITAR
    </Button>
  )
}

export default RejectButton
