import { Modal } from "react-bootstrap";
import Input from "../components/InputField";
import ReturnButton from "../components/ReturnButton";
import RejectButton from "./RejectButton";
import AcceptButton from "./AcceptButton";
import sanitizeCNPJ from "../utils/sanitizeCNPJ";
import Card from "react-bootstrap/Card";
import { useAuth } from "../webpages/index";
import { useState } from "react";
import ErrorModal from "./ErrorModal";
import capitalizeString from "../utils/capitalizeString";
import { ClipLoader } from "react-spinners";

const inputTexts = {
  pOpId: "Id da Operação",
  pMsg: "Mensagem ISO 20022",
  pInvest: "Investidor",
  pAplica: "Primeira Aplicação",
  pCNPJ: "CNPJ do Fundo",
  pNameFund: "Nome do Fundo",
  pValue: "Valor da Aplicação",
  pDateOp: "Data Operação",
  pDateCot: "Data Cotização Aplicação",
  pDateLiq: "Data Liquidação Resgate",
  pDateCotRes: "Data Cotização Resgate",
  pType: "Tipo Operação",
  pStatus: "Status da Operação",
  pTipoResgate: "Modalidade do Resgete",
};

const ModalOrders = ({
  modalState,
  closeModal,
  order,
  updateOrderStatus,
  index,
}) => {
  const cardTitleOption = `Detalhes`;
  const msgId = order.MsgId.Id;
  const clientId = order.MltplOrdrDtls.BnfcryDtls.Id;
  const orderType =
    order.MltplOrdrDtls.IndvOrdrDtls.OrdrTp.Id === "A"
      ? "Aplicação"
      : "Resgate";
  const fundCnpj = order.MltplOrdrDtls.IndvOrdrDtls.FinInstrmDtls.Id;
  const fundName = order.MltplOrdrDtls.IndvOrdrDtls.FinInstrmDtls.Nm;
  const orderId = order.MltplOrdrDtls.IndvOrdrDtls.OrdrRef;
  const orderDateTime = order.MltplOrdrDtls.OrdrDtTm;
  const amount =
    order.MltplOrdrDtls.IndvOrdrDtls.AmtOrUnitsOrPctg?.GrssAmt ||
    order.MltplOrdrDtls.IndvOrdrDtls.AmtOrUnits.GrssAmt;
  const addInfo = order.MltplOrdrDtls.IndvOrdrDtls.AdditionalDetails;
  const providers =
    order.MltplOrdrDtls.IndvOrdrDtls.AdditionalDetails.Providers;

  const [opStatus, setOpStatus] = useState(addInfo.OrderStatus.toUpperCase());
  const [statusAdmin, setStatusAdmin] = useState(
    capitalizeString(providers.Admin.Status)
  );
  const [statusCustodian, setStatusCustodian] = useState(
    capitalizeString(providers.Custodian.Status)
  );
  const [statusManager, setStatusManager] = useState(
    capitalizeString(providers.Manager.Status)
  );
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();
  const user = auth.user;

  const loaderState = (option) => {
    setIsLoading(option);
  };

  const openErrorModal = () => {
    setShowErrorModal(true);
    setErrorObj({
      header: "Erro na requisição solicitada",
      message:
        "Ocorreu um erro na requisição, aguarde alguns instantes e tente novamente. Caso o problema persista, entre em contato com o nosso suporte.",
    });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const updateAllStatus = (statusObj) => {
    // Check if statuses are filled => if not, just consider last state
    statusObj.admin = statusObj.admin || statusAdmin;
    statusObj.custodian = statusObj.custodian || statusCustodian;
    statusObj.manager = statusObj.manager || statusManager;
    statusObj.order = statusObj.order || opStatus;

    // Update all states - order, admin, custodian and manager
    updateOrderStatus(statusObj.order);
    setOpStatus(statusObj.order);
    setStatusAdmin(statusObj.admin);
    setStatusCustodian(statusObj.custodian);
    setStatusManager(statusObj.manager);

    // set isLoading to false
    loaderState(false);

    // hide two buttons - approve and reject
    document.querySelector("#accept-button").classList.add("d-none");
    document.querySelector("#reject-button").classList.add("d-none");
  };

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const sanitizeDate = (UTCDate) => {
    const date = Date.parse(UTCDate);
    return new Date(date).toLocaleDateString();
  };

  return (
    <Modal show={modalState} onHide={closeModal}>
      <ErrorModal
        show={showErrorModal}
        close={closeErrorModal}
        error={errorObj}
      />
      <div className="row modal-orders justify-content-around">
        <div className="offset-sm-8 col-sm-4 mt-3 mb-5 pl-5">
          <Card.Title className="no-margin">{cardTitleOption}</Card.Title>
        </div>
        <div className="col-sm-5 mt-4">
          <Input
            type="text"
            value={orderId}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pOpId}</p>
        </div>
        <div className="col-sm-5 mt-4">
          <Input
            type="text"
            value={`${msgId}.001.04`}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pMsg}</p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={clientId}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text" id="input-text-modal">
            {inputTexts.pInvest}
          </p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={formatter.format(amount)}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pValue}</p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={sanitizeCNPJ(fundCnpj)}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pCNPJ}</p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={opStatus}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pStatus}</p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={fundName}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pNameFund}</p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={sanitizeDate(orderDateTime)}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pDateOp}</p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={orderType}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pType}</p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={addInfo.QuoteDate}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text-modal">{inputTexts.pDateCot}</p>
        </div>
        <div className="col-sm-5">
          {orderType === "Aplicação" ? (
            <>
              <Input
                type="text"
                value={addInfo.FirstSubscription ? "SIM" : "NÃO"}
                readOnly
                className="label-modal-orders"
              />
              <p className="input-text-modal">{inputTexts.pAplica}</p>
            </>
          ) : (
            <>
              <Input
                type="text"
                value={
                  order.MltplOrdrDtls.IndvOrdrDtls.OrdrTp.Id === "RT"
                    ? "Resgate Total"
                    : "Resgate Parcial"
                }
                readOnly
                className="label-modal-orders"
              />
              <p className="input-text input-text-modal">
                {inputTexts.pTipoResgate}
              </p>
            </>
          )}
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={addInfo.RedemptionDate}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text input-text-modal">{inputTexts.pDateLiq}</p>
        </div>
        <div className="col-sm-11 justify-content-end text-center form-parties">
          <p>Partes</p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={`${providers.Custodian.Nm}`}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text d-flex justify-content-between mr-2 input-text-modal">
            Custodiante{" "}
            <span className={"status-" + statusCustodian.toLowerCase()}>
              Status: {statusCustodian}
            </span>
          </p>
        </div>
        <div className="col-sm-5">
          <Input
            type="text"
            value={providers.Admin.Nm}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text d-flex justify-content-between input-text-modal mr-2">
            Administrador{" "}
            <span className={"status-" + statusAdmin.toLowerCase()}>
              Status: {statusAdmin}
            </span>
          </p>
        </div>
        <div className="col-sm-5 flex-grow">
          <Input
            type="text"
            value={`${providers.Manager.Nm}`}
            readOnly
            className="label-modal-orders"
          />
          <p className="input-text d-flex justify-content-between input-text-modal mr-2">
            Gestor{" "}
            <span className={"status-" + statusManager.toLowerCase()}>
              Status: {statusManager}
            </span>
          </p>
        </div>
        <div className="col-sm-5 flex-grow"></div>
      </div>

      <div className="d-flex justify-content-center align-items-end my-4">
        {isLoading ? (
          <ClipLoader size={40} color="#bf2c4a" />
        ) : (
          <>
            <RejectButton
              loaderState={loaderState}
              openErrorModal={openErrorModal}
              rejectOrder={updateAllStatus}
              userProvider={user.provider}
              orderId={orderId}
              orderType={
                orderType === "Aplicação" ? "subscription" : "redemption"
              }
            />
            <AcceptButton
              loaderState={loaderState}
              openErrorModal={openErrorModal}
              approveOrder={updateAllStatus}
              userProvider={user.provider}
              orderId={orderId}
              orderType={
                orderType === "Aplicação" ? "subscription" : "redemption"
              }
            />
            <ReturnButton onClick={closeModal} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalOrders;
