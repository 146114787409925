import NavbarRtm from '../components/NavbarRtm'
import DashboardFilter from '../components/DashboardFilter'
import Card from 'react-bootstrap/Card'
import TableDashboard from '../components/TableDashboard'
import BarChart from '../components/BarChart'
import React, { useEffect, useState } from 'react'
import { getMetrics, getFundMetrics } from '../utils/dashboardInfos'
import { PulseLoader } from 'react-spinners'
import ErrorModal from '../components/ErrorModal'
import TableOrderStatus from '../components/TableOrderStatus'
import { FaFilter } from 'react-icons/fa'

const Dashboard = () => {
  const [metrics, setMetrics] = useState(null)
  const [fundMetrics, setFundMetrics] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleFundChange = async (cnpj) => {
    setFundMetrics(await getFundMetrics(cnpj))
  }

  const fetchData = async () => {
    setFundMetrics(null)
    setLoading(true)
    try {
      const response = await getMetrics()
      setMetrics(response)
      setLoading(false)
    } catch (error) {
      if (!error.response) {
        setError({
          header: 'Ocorreu um Erro',
          message:
            'Não conseguimos carregar as informações necessárias, tente novamente mais tarde.',
        })
      }
    }
  }

  const closeErrorModal = () => {
    setError(false)
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      {error && (
        <ErrorModal show={!!error} close={closeErrorModal} error={error} backHistory={true} />
      )}
      <NavbarRtm />
      <Card className="dashboard-card">
        <div id="dashboard-card-title">Acompanhamento</div>
        {loading ? (
          <Card.Body className="text-center m-5">
            <div className="dashboard-card-loading">Carregando as informações...</div>
            <PulseLoader size={30} color="#c53048" />
          </Card.Body>
        ) : metrics ? (
          <>
            <Card.Body>
              <div className="row d-flex justify-content-between my-3 px-5">
                <div className="col-sm-6 col-xs-12">
                  <div className="d-flex flex-column">
                    <h3 className="dashboard-filter-title mb-3">
                      <FaFilter className="mx-2 p-1 icon-border" />
                      Fundos de Investimento
                    </h3>
                    <DashboardFilter
                      metrics={metrics}
                      handleFundChange={handleFundChange}
                      refresh={fetchData}
                    />
                  </div>
                </div>
                <div className="col-sm-6  col-xs-12 flex-grow-1 d-flex my-3">
                  <BarChart metrics={metrics} fundMetrics={fundMetrics} />
                </div>
              </div>
              <div className="row d-flex my-3 px-5">
                <div className="col-sm-6 col-xs-12">
                  <TableDashboard metrics={metrics} fundMetrics={fundMetrics} />
                </div>
                <div className="col-sm-6 col-xs-12">
                  <TableOrderStatus metrics={metrics} fundMetrics={fundMetrics} />
                </div>
              </div>
            </Card.Body>
          </>
        ) : (
          <button
            className="btn-danger"
            id="update-btn"
            onClick={() => {
              fetchData()
            }}>
            Atualizar
          </button>
        )}
      </Card>
      <div className="col-sm-12 rtm-background-gray"></div>
    </div>
  )
}
export default Dashboard
