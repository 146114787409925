import { Step, StepLabel, Stepper } from '@material-ui/core'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { BsBellFill, BsChevronExpand } from 'react-icons/bs'
import decodeUserInfo from '../../utils/decodeUserInfo'
import sanitizeCNPJ from '../../utils/sanitizeCNPJ'
import OrdersTableVert from './OrdersTableVert'
import PositionsTableVert from './PositionsTableVert'
import { MessageContext } from '../../context'
import message from '../../utils/message'
import { GoCloudUpload } from 'react-icons/go'
import { ClipLoader } from 'react-spinners'
import updateBulkPortabilityFiles from '../../utils/updateBulkPortabilityFiles'
import DownloadFileDropDown from './DownloadFileDropDown'

export default function PortabilityDashboardRight({
  currentPortability,
  statusStep,
  notifications,
  forceRender,
}) {
  const { listMessage, setMessage } = useContext(MessageContext)
  const [investorId, setInvestorId] = useState('')
  const userToken = window.sessionStorage.getItem('token')
  const { user } = decodeUserInfo(userToken)
  const [modalShow, setModalShow] = useState(false)
  const [fileName, setFileName] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const hideList = (index) => {
    document.querySelector(`.order-details-${index}`).classList.toggle('d-none')
  }

  const hideListPosition = (index) => {
    document.querySelector(`.position-details-${index}`).classList.toggle('d-none')
  }

  const organizeDate = (str) => {
    const date = new Date(str)
    return date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
  }

  const steps = [
    'Cedente: Portabilidade solicitada',
    'Cessionário: Validação e implantação da posição',
    'Cessionário: Informe aos Administradores',
    'Administrador: Aprovação e troca de posição',
    'RTM: Confirmação às partes envolvidas',
    'Finalizado',
  ]

  const updateBulkPortability = async (currentPortability) => {
    const files = document.querySelectorAll('.input-file-selector')

    const { id } = currentPortability

    try {
      await updateBulkPortabilityFiles(files, id)
      forceRender((e) => !e)
      setModalShow(false)
      setIsLoading(false)
      message('Portabilidade atualizada com sucesso', listMessage, setMessage, 'success')
    } catch (error) {
      setModalShow(false)
      setIsLoading(false)
      message('Ocorreu algum erro na solicitação', listMessage, setMessage, 'error')
    }
  }

  const updatePortability = async (currentPortability, investorId = null) => {
    const { id } = currentPortability

    if (investorId?.length !== 0) {
      const body = { investorAdminIdToParty: investorId }

      try {
        await axios.put(`${process.env.REACT_APP_MVP_FUNDS}/portability/${id}`, body)
        forceRender((e) => !e)
        message('Portabilidade atualizada com sucesso', listMessage, setMessage, 'success')
      } catch (error) {
        message('Ocorreu algum erro na solicitação', listMessage, setMessage, 'error')
        console.log(error)
      }
    } else {
      try {
        await axios.put(`${process.env.REACT_APP_MVP_FUNDS}/portability/${id}`)
        forceRender((e) => !e)
        message('Portabilidade atualizada com sucesso', listMessage, setMessage, 'success')
      } catch (error) {
        message('Ocorreu algum erro na solicitação', listMessage, setMessage, 'error')
        console.log(error)
      }
    }
  }

  return (
    <div className="right-dashboard col-8">
      <Modal show={modalShow} onHide={setModalShow}>
        <Form className="d-flex flex-column card-bulk my-4">
          <Form.Group controlId="balances" className="py-4">
            <Form.Control
              size="lg"
              type="file"
              className="d-none input-file-selector my-4"
              onChange={(e) => {
                let result
                e.target.value.includes('/')
                  ? (result = e.target.value.split('/'))
                  : (result = e.target.value.split('\\'))
                const length = result.length
                setFileName({ ...fileName, balances: result[length - 1] })
              }}
              required
            />
            <Form.Label className="choose-file-button shadow-md">
              <GoCloudUpload className="mx-2" /> {fileName?.balances || 'Pesquisar'}
            </Form.Label>
            <p className="input-file-label input-text-port input-text mt-3">
              Arquivo de Posições Consolidadas
            </p>
          </Form.Group>
          <Form.Group controlId="orders">
            <Form.Control
              size="lg"
              type="file"
              className="d-none input-file-selector"
              onChange={(e) => {
                let result
                e.target.value.includes('/')
                  ? (result = e.target.value.split('/'))
                  : (result = e.target.value.split('\\'))
                const length = result.length
                setFileName({ ...fileName, orders: result[length - 1] })
              }}
              required
            />
            <Form.Label className="choose-file-button shadow-md">
              <GoCloudUpload className="mx-2" /> {fileName?.orders || 'Pesquisar'}
            </Form.Label>
            <p className="input-file-label input-text-port input-text mt-3">
              Arquivo de Movimentações
            </p>
          </Form.Group>
          <div className="d-flex justify-content-center">
            {isLoading ? (
              <ClipLoader size={40} color="#bf2c4a" />
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  setIsLoading(true)
                  updateBulkPortability(currentPortability)
                }}>
                UPLOAD
              </Button>
            )}
          </div>
        </Form>
      </Modal>
      {currentPortability ? (
        <>
          <div className="d-flex justify-content-between align-items-end">
            <h4 className="text-gray m-0">
              <em>Consulta Portabilidade</em>
            </h4>
            <DownloadFileDropDown currentPortability={currentPortability} />
          </div>
          <hr />
          <Table striped className="text-gray">
            <tbody>
              <tr>
                <th>Identificação</th>
                <td colSpan={2} className="text-center">
                  {currentPortability?.id}
                </td>
              </tr>
              <tr>
                <th>Cedente / CNPJ</th>
                <td className="text-center">{currentPortability?.fromPartyName}</td>
                <td className="text-center">{sanitizeCNPJ(currentPortability?.fromParty)}</td>
              </tr>
              <tr>
                <th>Cessionário / CNPJ</th>
                <td className="text-center">{currentPortability?.toPartyName}</td>
                <td className="text-center">{sanitizeCNPJ(currentPortability?.toParty)}</td>
              </tr>
              <tr>
                <th>Status da Portabilidade</th>
                <td colSpan={2} className="text-center">
                  {currentPortability?.portabilityStatus}
                </td>
              </tr>
            </tbody>
          </Table>
          {console.log(currentPortability.consolidatedBalances ? true : false)}
          {currentPortability.consolidatedBalances?.length ? (
            <>
              <h4 className="text-gray mt-5">
                <em>Posições consolidadas</em>
              </h4>
              <hr />
              {currentPortability.consolidatedBalances?.map((position, index) => {
                return (
                  <div key={'position' + index} className="position-relative">
                    <h5
                      className="text-red"
                      onClick={() => {
                        hideListPosition(index)
                      }}>
                      Posição #{index + 1} <BsChevronExpand />
                    </h5>
                    <PositionsTableVert
                      data={position}
                      className={`position-details-${index} d-none`}
                      investorId={investorId}
                      setInvestorId={setInvestorId}
                      portability={currentPortability}
                    />
                  </div>
                )
              })}
            </>
          ) : null}
          {currentPortability.orders?.length ? (
            <>
              <h4 className="text-gray mt-5">
                <em>Movimentações</em>
              </h4>
              <hr />
              {currentPortability.orders?.map((order, index) => {
                return (
                  <>
                    <h5
                      className="text-red"
                      onClick={() => {
                        hideList(index)
                      }}>
                      Movimentação #{index + 1} <BsChevronExpand />
                    </h5>
                    <OrdersTableVert
                      data={order}
                      fundName={
                        currentPortability?.consolidatedBalances?.[0]?.fundName ||
                        currentPortability?.orders?.[0]?.fundName
                      }
                      className={`order-details-${index} d-none`}
                    />
                  </>
                )
              })}
            </>
          ) : null}
          {currentPortability?.toParty === user.cnpj &&
            currentPortability?.portabilityStatus === 'OK_TRANSFEROR' && (
              <div className="d-flex justify-content-center">
                <Button
                  className="inform-adm my-3"
                  onClick={() => {
                    updatePortability(currentPortability, investorId)
                  }}>
                  Informar Administradores
                </Button>
              </div>
            )}
          {currentPortability.portabilityStatus === 'INFORM_ADMINS' &&
            user.provider === 'administrador' && (
              <div className="d-flex justify-content-center">
                <Button
                  className="inform-adm my-3 mx-3"
                  variant="secondary"
                  onClick={() => {
                    setModalShow(true)
                  }}>
                  Atualização em lote
                </Button>
                <Button
                  className="inform-adm my-3 mx-3"
                  onClick={() => {
                    updatePortability(currentPortability)
                  }}>
                  Confirmar & Informar
                </Button>
              </div>
            )}
          <Stepper activeStep={statusStep} alternativeLabel className="stepper my-4">
            {steps.map((label) => (
              <Step key={label} className="step">
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      ) : (
        <>
          <h4 className="text-red">
            <BsBellFill className="mr-3" />
            <em>Central de Notificações</em>
          </h4>
          <hr />
          <ul className="pl-0">
            {notifications &&
              notifications
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((notification, index) => {
                  return (
                    <div key={notification + index}>
                      <li
                        className="notification-item fs-6 d-flex align-items-center mb-4"
                        key={`notification-${index}`}>
                        <div className="flex-shrink-0 mr-3">
                          <span className="text-gray text-small">
                            {organizeDate(notification.created_at)
                              .split(' ')
                              .map((e) => (
                                <div className="text-center" key={`notification-${e}`}>
                                  {e}
                                </div>
                              ))}
                          </span>
                        </div>
                        <div className="wrapper">
                          <div className="notification-title text-red">{notification.subject}</div>
                          <div className="notification-body">{notification.message}</div>
                        </div>
                      </li>
                    </div>
                  )
                })}
          </ul>
        </>
      )}
    </div>
  )
}
