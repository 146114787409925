const sanitizeCPF = (string) => {
  try {
    if (!string.includes('.') && string.length === 11) {
      let result = ''
      if (string) {
        result =
          string.slice(0, 3) +
          '.' +
          string.slice(3, 6) +
          '.' +
          string.slice(6, 9) +
          '-' +
          string.slice(9, 11)
      }
      return result
    }
    return string
  } catch (error) {
    return string
  }
}

export default sanitizeCPF
