import axios from 'axios'
import { CORE_URL } from './config'

async function postBulkFile(orderType) {
  let formData = new FormData()
  const authString = `Bearer ${window.sessionStorage.getItem('token')}`
  const headers = {
    headers: {
      Authorization: authString,
      'Content-Type': 'multipart/form-data',
    },
  }

  const bulkFile = document.querySelector('.input-file-selector')
  formData.append('file', bulkFile.files[0])
  const body = formData

  try {
    const response = await axios.post(`${CORE_URL}/api/${orderType}s/bulk`, body, headers)
    if (response.status !== 200) {
      throw Error('Erro na requisição')
    }
    return response.data.data
  } catch (error) {
    console.error(error)
    return false
  }
}

export default postBulkFile
