const InputRadio = ({ onInputChange, opType }) => {
  return (
    <>
      <div className="form-font">
        <input
          className="radio-special form-font-radio"
          id="rd1"
          type="radio"
          name="aplicacao"
          value={true}
          onChange={onInputChange}
        />
        {opType === 'Aplicação' ? 'Sim' : 'Parcial'}
        <label className="mx-2" htmlFor="rd1"></label>
        <input
          className="radio-special form-font-radio"
          id="rd2"
          type="radio"
          name="aplicacao"
          value={false}
          onChange={onInputChange}
        />
        {opType === 'Aplicação' ? 'Não' : 'Total'}
        <label className="mx-2" htmlFor="rd2"></label>
      </div>
    </>
  )
}

export default InputRadio
