import axios from 'axios'
import { CORE_URL } from './config'

const deleteOrder = async (userProvider, orderId, orderType) => {
  let capitalizedProvider = ''
  switch (userProvider) {
    case 'administrador':
      capitalizedProvider = 'Admin'
      break
    case 'gestor':
      capitalizedProvider = 'Manager'
      break
    case 'custodiante':
      capitalizedProvider = 'Custodian'
      break

    default:
      break
  }

  const authString = `Bearer ${window.sessionStorage.getItem('token')}`
  const headers = {
    headers: {
      provider: capitalizedProvider,
      Authorization: authString,
    },
  }

  try {
    const response = await axios.delete(`${CORE_URL}/api/${orderType}/${orderId}`, headers)
    console.log(response)
    const updatedStatus = { order: 'Recusado' }

    switch (userProvider) {
      case 'administrador':
        updatedStatus.admin = 'Recusado'
        break
      case 'gestor':
        updatedStatus.manager = 'Recusado'

        break
      case 'custodiante':
        updatedStatus.custodian = 'Recusado'
        break

      default:
        break
    }

    return updatedStatus
  } catch (error) {
    console.log(error)
    return error
  }
}

export default deleteOrder
