import React, { useEffect, useState } from 'react'
import LoginCard from '../components/login/loginCard'
import NavbarLogin from '../components/login/navbarLogin'
import { MdFingerprint } from 'react-icons/md'
import Banner from '../components/Banner'
import ErrorModal from '../components/ErrorModal'

const Login = (props) => {
  const [error, setError] = useState({})
  const [showErrorModal, setShowErrorModal] = useState(false)

  useEffect(() => {
    if (props.location?.state.showErrorModal) {
      setShowErrorModal(true)
      setError(props.location?.state.error)
    }
  }, [props.location?.state.error, props.location?.state.showErrorModal])

  const handleErrorModalClose = () => {
    setShowErrorModal(false)
  }

  const handleSetError = (errorObj) => {
    setError(errorObj)
    setShowErrorModal(true)
  }

  return (
    <>
      <NavbarLogin />
      <Banner iconPosition="right" size="sm">
        <MdFingerprint color="#C7CAD3" size="180" />
        Área Restrita
      </Banner>
      <div className="z-100">
        <ErrorModal
          close={handleErrorModalClose}
          show={showErrorModal}
          error={error}
        />
        <LoginCard
          title="Login"
          fromUrl={props.location?.from || ''}
          handleSetError={handleSetError}
        />
      </div>
    </>
  )
}
export default Login
