import React from 'react'
import { Table } from 'react-bootstrap'

export default function OrdersTable({ data }) {
  return (
    <Table className="my-4 orders-table" responsive size="md">
      <thead>
        <tr>
          <th className="text-red">Tipo movimentação</th>
          <th className="text-red">Data da movimentação</th>
          <th className="text-red">Código da ordem</th>
          <th className="text-red">Nota da aplicação</th>
          <th className="text-red">Data da cotização</th>
          <th className="text-red">Quantidade de cotas</th>
          <th className="text-red">Valor da cota</th>
          <th className="text-red">Valor bruto</th>
          <th className="text-red">IR</th>
          <th className="text-red">IOF</th>
          <th className="text-red">Valor Líquido</th>
          <th className="text-red">Rendimento Bruto</th>
          <th className="text-red">Código da movimentação</th>
          <th className="text-red">Informações Adicionais</th>
        </tr>
      </thead>
      <tbody>
        {data.orders.map((order, i) => (
          <tr key={'order' + i}>
            <td>{order?.orderType}</td>
            <td>{order?.orderDate}</td>
            <td>{order?.certId}</td>
            <td>{order?.subscriptionId}</td>
            <td>{order?.shareDate}</td>
            <td>{order?.sharesQuantity}</td>
            <td>{order?.sharesAmount}</td>
            <td>{order?.grossAmount}</td>
            <td>{order?.incomeTax}</td>
            <td>{order?.financialOperationTax}</td>
            <td>{order?.netAmount}</td>
            <td>{order?.grossIncome}</td>
            <td>{order?.movementCode}</td>
            <td>{order?.additionalInfo}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
