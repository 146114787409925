import { Redirect } from 'react-router-dom'

const Confirmed = (props) => {
  return (
    <Redirect
      to={{
        pathname: '/login',
        from: props.location,
      }}
    />
  )
}

export default Confirmed
