import { Button, Modal } from "react-bootstrap";
import { BiError } from "react-icons/bi";
import { useHistory } from "react-router-dom";

const ErrorModal = ({ show, close, error, backHistory = false }) => {
  const history = useHistory();
  const handleBackButton = () => {
    if (backHistory) {
      history.goBack();
    }
    close();
  };

  return (
    <>
      <Modal show={show} onHide={close} id="error-modal">
        <Modal.Header closeButton className="error-modal-header text-center">
          <Modal.Title className="error-modal-title d-flex flex-grow-1 align-items-center justify-content-center">
            <BiError className="mx-2" />
            {error.header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="error-modal-body ">
          <div className="mb-3">{error.message}</div>
          <Button
            className="text-uppercase mx-2"
            variant="primary"
            onClick={handleBackButton}
          >
            {backHistory ? "Voltar" : "Fechar"}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ErrorModal;
