import React from 'react'
import Button from 'react-bootstrap/Button'

const ReturnButton = ({ onClick }) => {
  return (
    <Button variant="secondary" onClick={onClick}>
      VOLTAR
    </Button>
  )
}

export default ReturnButton
