import React from 'react'

const TableHeaderBunks = () => {
  return (
    <thead className="table-header">
      <tr>
        <th className="border-danger text-center">#</th>
        <th className="border-danger">CNPJ DO FUNDO</th>
        <th className="border-danger">NOME DO FUNDO</th>
        <th className="border-danger">STATUS</th>
        <th className="border-danger">VALOR</th>
        <th className="border-danger">IDENTIFICAÇÃO DA ORDEM</th>
      </tr>
    </thead>
  )
}

export default TableHeaderBunks
