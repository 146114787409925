import React from 'react'
import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { AiOutlineRight } from 'react-icons/ai'
import { IoAdd, IoArrowBack } from 'react-icons/io5'
import MaskedInput from 'react-input-mask'
import sanitizeCPF from '../../utils/sanitizeCpf'

export default function OrderPortabilityForm({
  balanceData,
  investorData,
  orderData,
  setOrderData,
  handleFormStep,
  formStep,
  user,
  checkPortabilityData,
}) {
  const [order, setOrder] = useState({
    fundId: balanceData?.[0]?.fundId,
    investorAdminId: balanceData?.[0]?.investorAdminIdFromParty,
  })

  const handleInput = (e) => {
    setOrder((values) => {
      return { ...values, [e.target.name]: e.target.value }
    })
  }

  const renderOrderType = (orderType) => {
    switch (orderType) {
      case 'COMECOTAS':
        return 'Come Cotas'
      case 'REDEMPTION_TOTAL':
        return 'Resgate Total'
      case 'REDEMPTION_PARTIAL':
        return 'Resgate Parcial'
      case 'REDEMPTION_SHARES_AMOUNT':
        return 'Resgate por Qtd. Cotas'
      default:
        return 'Aplicação'
    }
  }

  return (
    <div className="step2">
      <IoArrowBack className="portability-back-icon" onClick={() => handleFormStep(formStep - 1)} />
      <h1 className="text-red mb-5 text-center">Informações de Movimentação</h1>

      <div className="step2 d-flex flex-wrap">
        <div className="title mt-4 col-12">
          <h5 className="text-red">
            <em>Informações do Cotista</em>
          </h5>
          <hr className="portability-form-divider" />
        </div>
        <Form.Group className="mb-3 col-4" controlId="fullName">
          <Form.Label>
            Nome do Cliente <strong>*</strong>
          </Form.Label>

          <Form.Control
            type="text"
            placeholder="Nome completo do investidor"
            name="fullName"
            value={investorData?.fullName || 'Investidor não informado'}
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3 col-4" controlId="cpfCgc">
          <Form.Label>
            CPF/CGC <strong>*</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="CPF ou CGC do investidor"
            name="cpfCgc"
            value={sanitizeCPF(investorData?.cpfCgc) || 'Investidor não informado'}
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3 col-4" controlId="investorAdminId">
          <Form.Label>Código do Cliente no Administrador</Form.Label>
          <Form.Control
            type="text"
            placeholder="Código Cliente ADM"
            value={order?.investorAdminId}
            name="investorAdminId"
            disabled
          />
        </Form.Group>

        <div className="title mt-4 col-12">
          <h5 className="text-red">
            <em>Informações do Fundo</em>
          </h5>
          <hr className="portability-form-divider" />
        </div>
        <Form.Group className="mb-3 col-6" controlId="fundName">
          <Form.Label>Razão Social</Form.Label>
          <Form.Control
            type="text"
            placeholder="N/A"
            name="fundName"
            value={balanceData?.[0]?.fundName || ''}
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="fundId">
          <Form.Label>CNPJ do Fundo</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              className="form-control x-form"
              type="text"
              placeholder="N/A"
              name="fundId"
              value={balanceData?.[0]?.fundId || ''}
              onChange={(e) => {
                handleInput(e)
              }}
              disabled
            />
          </div>
        </Form.Group>

        <div className="title mt-4 col-12">
          <h5 className="text-red">
            <em>Informações da Movimentação</em>
          </h5>
          <hr className="portability-form-divider" />
        </div>
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
          <Form.Label>Distribuidor Cedente</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nome Cedente"
            value={user?.contactName.toUpperCase() && user.contactName.toUpperCase()}
            readOnly={true}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="orderType">
          <Form.Label>Tipo da Movimentação</Form.Label>

          <select className="select-port" name="orderType" onChange={(e) => handleInput(e)}>
            <option value="default">Escolha o tipo de movimentação</option>
            <option value="SUBSCRIPTION">Aplicação</option>
            <option value="REDEMPTION_PARTIAL">Resgate Parcial</option>
            <option value="REDEMPTION_TOTAL">Resgate Total</option>
            <option value="REDEMPTION_SHARES_AMOUNT">Resgate por Quantidade de Cotas</option>
            <option value="COMECOTAS">Resgate pagamento de IR</option>
          </select>
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="orderDate">
          <Form.Label>Data da Movimentação</Form.Label>
          <MaskedInput
            className="form-control"
            mask="99/99/9999"
            type="text"
            placeholder="DD/MM/AAAA"
            name="orderDate"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="certId">
          <Form.Label>Código da Ordem</Form.Label>
          <MaskedInput
            className="form-control"
            type="text"
            placeholder="Número de identificação do certificado"
            name="certId"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="subscriptionId">
          <Form.Label>Nota da Aplicação</Form.Label>
          <MaskedInput
            className="form-control"
            type="text"
            placeholder="Número de identificação da aplicação"
            name="subscriptionId"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="shareDate">
          <Form.Label>Data da Cotização</Form.Label>
          <MaskedInput
            className="form-control"
            mask="99/99/9999"
            type="text"
            placeholder="DD/MM/AAAA"
            name="shareDate"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="sharesQuantity">
          <Form.Label>Quantidade de Cotas</Form.Label>
          <Form.Control
            type="text"
            name="sharesQuantity"
            placeholder="Quantidade total de cotas movimentadas"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="sharesAmount">
          <Form.Label>Valor Cotas</Form.Label>
          <Form.Control
            type="text"
            name="sharesAmount"
            placeholder="R$"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="grossAmount">
          <Form.Label>Valor Bruto</Form.Label>
          <Form.Control
            type="text"
            name="grossAmount"
            placeholder="R$"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="incomeTax">
          <Form.Label>
            Imposto de Renda <em>(IR)</em>
          </Form.Label>{' '}
          <Form.Control
            type="text"
            placeholder="R$"
            name="incomeTax"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="financialOperationTax">
          <Form.Label>
            Imposto sobre Operações Financeiras <em>(IOF)</em>
          </Form.Label>{' '}
          <Form.Control
            type="text"
            placeholder="R$"
            name="financialOperationTax"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-6" controlId="netAmount">
          <Form.Label>Valor Líquido</Form.Label>
          <Form.Control
            type="text"
            name="netAmount"
            placeholder="R$"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="grossIncome">
          <Form.Label>Rendimento Bruto</Form.Label>
          <Form.Control
            type="text"
            name="grossIncome"
            placeholder="R$"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="movementCode">
          <Form.Label>Código da Movimentação</Form.Label>
          <Form.Control
            type="text"
            name="movementCode"
            placeholder="Código da Movimentação"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-6" controlId="additionalInfo">
          <Form.Label>Informação Adicional</Form.Label>
          <Form.Control
            type="text"
            name="additionalInfo"
            placeholder="Campo Livre"
            onChange={(e) => handleInput(e)}
          />
        </Form.Group>
      </div>
      <div className="d-flex justify-content-center my-2">
        <div
          className="btn-add-order shadow-lg d-flex justify-content-center align-items-center"
          onClick={() => {
            setOrderData((values) => [...values, order])
          }}>
          <IoAdd fontSize={'2rem'} />
        </div>
      </div>
      <div className="orders d-flex flex-wrap my-4 justify-content-around">
        {orderData.length > 0 && (
          <div className="d-flex justify-content-center col-12">
            <h4 className="text-red">Movimentações registradas</h4>
          </div>
        )}
        {orderData &&
          orderData.map((element, index) => {
            return (
              <div className="col-5 order-cards m-3 px-4 py-2" key={`order-${index}`}>
                <div className="order-number d-flex justify-content-center align-items-center">
                  <strong className="text-white">{index + 1}</strong>
                </div>{' '}
                <div className="d-flex flex-column">
                  <table className="portability-order-table">
                    <tbody>
                      <tr>
                        <td>Código da Ordem:</td>
                        <td>
                          <em className="text-red">{element.certId}</em>
                        </td>
                      </tr>
                      <tr>
                        <td>Tipo Movimentação:</td>
                        <td>
                          <em className="text-red">{renderOrderType(element.orderType)}</em>
                        </td>
                      </tr>
                      <tr>
                        <td>Fundo:</td>
                        <td>
                          <em className="text-red">{element.fundId}</em>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )
          })}
        <div className="d-flex col-12 justify-content-center">
          <Button
            variant="primary"
            onClick={() => {
              checkPortabilityData()
            }}
            className="d-flex align-items-center justify-content-center mt-4">
            Finalizar
            <AiOutlineRight className="ml-2" />
          </Button>
        </div>
      </div>
    </div>
  )
}
