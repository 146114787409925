import axios from 'axios'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { AiOutlineRight } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'
import { IoAdd, IoArrowBack } from 'react-icons/io5'
import MaskedInput from 'react-input-mask'
import sanitizeCPF from '../../utils/sanitizeCpf'

export default function PositionForm({
  positions,
  investorData,
  setBalanceData,
  setFormStep,
  formStep,
  user,
}) {
  const [position, setPosition] = useState({})
  const [fundName, setFundName] = useState('')

  const handleInput = (e) => {
    setPosition((values) => {
      return { ...values, [e.target.name]: e.target.value }
    })
  }

  const addPosition = () => {
    setBalanceData((values) => [...values, position])
  }

  const searchFund = async (value) => {
    const cnpj = value?.replace(/_|-|\/|\./g, '')
    try {
      const headers = { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
      const res = await axios.get(`${process.env.REACT_APP_RTM_CORE_URL}/api/funds/${cnpj}`, {
        headers,
      })
      setFundName(res.data.nomeFantasia)
      setPosition((values) => {
        return { ...values, fundName: res.data.nomeFantasia }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="step1">
        <IoArrowBack className="portability-back-icon" onClick={() => setFormStep(formStep - 1)} />
        <div className="text-center">
          <h1 className="text-red text-center">Posição Consolidada</h1>
          <small className="text-red mb-5 text-center">
            <strong>*</strong>
            <em>
              Informações sensíveis dos investidores só serão compartilhadas entre Cedente e
              Cessionário.
            </em>
          </small>
        </div>

        <div className="d-flex flex-wrap">
          <div className="title mt-4 col-12">
            <h5 className="text-red">
              <em>Informações do Cotista</em>
            </h5>
            <hr className="portability-form-divider" />
          </div>
          <Form.Group className="mb-3 col-4" controlId="investorAdminIdFromParty">
            <Form.Label>Código do Investidor no Administrador</Form.Label>
            <Form.Control
              type="text"
              placeholder="Código Cliente ADM"
              name="investorAdminIdFromParty"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-4" controlId="fullName">
            <Form.Label>
              Nome do Cliente <strong>*</strong>
            </Form.Label>

            <Form.Control
              type="text"
              placeholder="Nome completo do investidor"
              name="fullName"
              value={investorData?.fullName || 'Investidor não informado'}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3 col-4" controlId="cpfCgc">
            <Form.Label>
              CPF/CGC <strong>*</strong>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="CPF ou CGC do investidor"
              name="cpfCgc"
              value={sanitizeCPF(investorData?.cpfCgc) || 'Investidor não informado'}
              disabled
            />
          </Form.Group>

          <div className="title mt-4 col-12">
            <h5 className="text-red">
              <em>Informações do Fundo</em>
            </h5>
            <hr className="portability-form-divider" />
          </div>

          <Form.Group className="mb-3 col-6" controlId="fundId">
            <Form.Label>CNPJ do Fundo</Form.Label>
            <div className="d-flex align-items-center">
              <MaskedInput
                className="form-control x-form"
                type="text"
                mask="99.999.999/9999-99"
                placeholder="CNPJ"
                name="fundId"
                onChange={(e) => {
                  handleInput(e)
                }}
              />
              <FaSearch
                className="ml-2 shadow"
                onClick={() => searchFund(position.fundId)}
                id="search-icon"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="fundName">
            <Form.Label>Razão Social</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome do Fundo"
              name="fundName"
              value={fundName}
              disabled
            />
          </Form.Group>

          <div className="title mt-4 col-12">
            <h5 className="text-red">
              <em>Informações da Posição</em>
            </h5>
            <hr className="portability-form-divider" />
          </div>

          <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label>Distribuidor Cedente</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome Cedente"
              value={user?.contactName.toUpperCase() && user.contactName.toUpperCase()}
              readOnly={true}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6" controlId="certId">
            <Form.Label>Nota</Form.Label>
            <Form.Control
              type="text"
              placeholder="Número de identificação do certificado"
              name="certId"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="movmentDate">
            <Form.Label>Data da Movimentação</Form.Label>
            <MaskedInput
              className="form-control"
              type="text"
              mask="99/99/9999"
              placeholder="DD/MM/AAAA"
              name="movmentDate"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="originalSubscription">
            <Form.Label>Aplicação Original</Form.Label>
            <Form.Control
              type="number"
              placeholder="R$"
              name="originalSubscription"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="sharesDate">
            <Form.Label>Data da Cota</Form.Label>
            <MaskedInput
              className="form-control"
              type="text"
              mask="99/99/9999"
              placeholder="DD/MM/AAAA"
              name="sharesDate"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="sharesQuantity">
            <Form.Label>Quantidade de Cotas</Form.Label>
            <Form.Control
              type="number"
              placeholder="Quantidade de cotas acumuladas"
              name="sharesQuantity"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="sharesAmount">
            <Form.Label>Valor da Cota</Form.Label>
            <Form.Control
              type="number"
              placeholder="Valor da cota"
              name="sharesAmount"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="grossAmount">
            <Form.Label>Valor Bruto</Form.Label>
            <Form.Control
              type="number"
              placeholder="R$"
              name="grossAmount"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="incomeTax">
            <Form.Label>
              Imposto de Renda <em>(IR)</em>
            </Form.Label>{' '}
            <Form.Control
              type="number"
              placeholder="R$"
              name="incomeTax"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="financialOperationTax">
            <Form.Label>
              Imposto sobre Operações Financeiras <em>(IOF)</em>
            </Form.Label>{' '}
            <Form.Control
              type="number"
              placeholder="R$"
              name="financialOperationTax"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="netAmount">
            <Form.Label>Valor Líquido</Form.Label>
            <Form.Control
              type="number"
              placeholder="R$"
              name="netAmount"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="certAge">
            <Form.Label>Idade da nota</Form.Label>
            <Form.Control
              type="text"
              placeholder="Dias após aplicação original"
              name="certAge"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="lastIncomeTaxDate">
            <Form.Label>Data último resgate IR</Form.Label>
            <MaskedInput
              className="form-control"
              type="text"
              mask="99/99/9999"
              placeholder="DD/MM/AAAA"
              name="lastIncomeTaxDate"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-6" controlId="lastIncomeTaxAmount">
            <Form.Label>Valor último resgate IR</Form.Label>
            <Form.Control
              type="number"
              placeholder="R$"
              name="lastIncomeTaxAmount"
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
        </div>
        <div className="d-flex justify-content-center my-2">
          <div
            className="btn-add-order shadow-lg d-flex justify-content-center align-items-center"
            onClick={() => {
              addPosition()
            }}>
            <IoAdd fontSize={'2rem'} />
          </div>
        </div>
        <div className="orders d-flex flex-wrap my-4 justify-content-around">
          {positions.length > 0 && (
            <div className="d-flex justify-content-center col-12">
              <h4 className="text-red">Posições registradas</h4>
            </div>
          )}
          {positions &&
            positions.map((element, index) => {
              return (
                <div className="col-5 order-cards m-3 px-4 py-2" key={`order-${index}`}>
                  <div className="order-number d-flex justify-content-center align-items-center">
                    <strong className="text-white">{index + 1}</strong>
                  </div>{' '}
                  <div className="d-flex flex-column">
                    <table className="portability-order-table">
                      <tbody>
                        <tr>
                          <td>Posição #:</td>
                          <td>
                            <em className="text-red">{index + 1}</em>
                          </td>
                        </tr>
                        <tr>
                          <td>Nota:</td>
                          <td>
                            <em className="text-red">{element.certId}</em>
                          </td>
                        </tr>
                        <tr>
                          <td>Fundo:</td>
                          <td>
                            <em className="text-red">{fundName}</em>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          variant="primary"
          onClick={() => {
            setFormStep(formStep + 1)
          }}
          className="d-flex align-items-center justify-content-center mt-4">
          {formStep === 0 ? 'Inciar Solicitação' : 'Avançar'} <AiOutlineRight className="ml-2" />
        </Button>
      </div>
    </>
  )
}
