import axios from 'axios'

async function postPortabilityFile(files, portabilityData, fileStandard) {
  let formData = new FormData()

  files.forEach((file) => {
    formData.append(file.id, file.files[0])
  })

  Object.keys(portabilityData).forEach((key) => {
    formData.append(key, portabilityData[key])
  })

  const body = formData
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_MVP_FUNDS}/portability/upload/file/${fileStandard}`,
      body
    )
    if (response.status < 200 || response.status > 300) {
      throw Error('Erro na requisição')
    }
    return response.data
  } catch (error) {
    console.error(error)
    return false
  }
}

export default postPortabilityFile
