import axios from "../utils/config";

const postRedemption = async (txData, fund) => {
  if (txData.cnpjFundo.length === 6) {
    txData.cnpjFundo = fund.cnpjFundo
      .replaceAll(".", "")
      .replaceAll("/", "")
      .replaceAll("-", "");
  }

  const data = {
    MsgId: {
      Id: "setr.004",
      CreDtTm: "2021-07-20T21:41:40.060Z",
    },
    MltplOrdrDtls: {
      MstrRef: "MSTRREF123456",
      OrdrDtTm: `${new Date()}`,
      ReqdFutrTradDt: `${new Date(Date.now() + Math.random() * 1000000000)}`,
      AcctId: "12345",
      BnfcryDtls: {
        Nm: "XPTOPLACEHOLDER",
        Id: `${txData.investor}`,
      },
      IndvOrdrDtls: {
        OrdrRef: "REF12345",
        OrdrTp: {
          Id: txData.partialRedemption === "true" ? "RP" : "RT",
        },
        FinInstrmDtls: {
          Id: `${txData.cnpjFundo}`,
          Nm: `${fund.nomeFantasia}`,
        },
        AmtOrUnits: {
          UnitsNb: 123,
          GrssAmt: `${txData.amount}`,
        },
        CshSttlmDtls: {
          Ref: txData.ref,
          ClrSysMmbId: txData.clr,
          DbtrAcct: {
            Id: txData.idd,
            Issr: txData.issr,
            Cd: txData.cd,
          },
        },
        AdditionalDetails: {
          FirstSubscription: txData.firstTime === "true" ? true : false,
        },
      },
    },
  };
  const authString = `Bearer ${window.sessionStorage.getItem("token")}`;
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authString,
    },
  };

  await axios
    .post(`/api/redemptions`, data, headers)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default postRedemption;
