import React from 'react'
import { Table } from 'react-bootstrap'

export default function IdentificationsTable({ data }) {
  const investor = data?.investor

  return (
    <Table className="my-4 orders-table" size="md">
      <thead>
        <tr>
          <th className="text-red">Nome do Cliente</th>
          <th className="text-red">CPF / CGC</th>
          <th className="text-red">Código Investidor no Administrador (Cedente)</th>
          {/* <th className="text-red">Código Investidor (Sinacor)</th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{investor?.fullName}</td>
          <td>{investor?.cpfCgc}</td>
          <td>{data?.consolidatedBalances?.[0]?.investorAdminIdFromParty}</td>
          {/* <td>{investor?.sinacorCode}</td> */}
        </tr>
      </tbody>
    </Table>
  )
}
