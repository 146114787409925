import axios from './config'
import decodeUserInfo from './decodeUserInfo'

export const authRequest = async (user) => {
  const response = await axios.post(`${process.env.REACT_APP_RTM_CORE_URL}/auth/login`, user)
  window.sessionStorage.setItem('token', response.data.token)
  return response.data.token
}

export const expiredSession = () => {
  try {
    const token = window.sessionStorage.getItem('token')
    if (!token) {
      console.log('no user logged')
      return {
        header: 'Área restrita',
        message: 'Inicie uma sessão para acessar os recursos solicitados.',
      }
    }

    const userInfo = decodeUserInfo(token)
    const now = new Date()
    const exp = new Date(userInfo.exp * 1000)
    if (now > exp) {
      console.log('session expired')
      return {
        header: 'Sua sessão expirou',
        message: 'Inicie uma sessão válida para acessar os recursos solicitados.',
      }
    }
  } catch {
    return {
      header: 'Área restrita',
      message: 'Inicie uma sessão para acessar os recursos solicitados.',
    }
  }

  return false
}
