import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const formatData = (metrics) => {
  return {
    labels: ["Confirmadas", "Em Processamento", "Rejeitadas", "Finalizadas"],
    datasets: [
      {
        label: "Número de Aplicações",
        data: [
          metrics?.orderStatus?.confirmed?.operations,
          metrics?.orderStatus?.processing?.operations,
          metrics?.orderStatus?.rejected?.operations,
          metrics?.orderStatus?.closed?.operations,
        ],
        backgroundColor: [
          "rgba(197, 48, 72, 0.7)",
          "rgba(197, 48, 72, 0.7)",
          "rgba(197, 48, 72, 0.7)",
          "rgba(197, 48, 72, 0.7)",
          "rgba(197, 48, 72, 0.7)",
          "rgba(197, 48, 72, 0.7)",
          "rgba(197, 48, 72, 0.7)",
        ],
        borderColor: [
          "rgba(197, 48, 72, 1)",
          "rgba(197, 48, 72, 1)",
          "rgba(197, 48, 72, 1)",
          "rgba(197, 48, 72, 1)",
          "rgba(197, 48, 72, 1)",
          "rgba(197, 48, 72, 1)",
          "rgba(197, 48, 72, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
};

const BarChart = ({ metrics, fundMetrics }) => {
  const [chartData, setChartData] = useState(formatData(metrics));

  useEffect(() => {
    if (fundMetrics) setChartData(formatData(fundMetrics));
  }, [fundMetrics]);

  return (
    metrics && (
      <Bar
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          title: { text: "NUMERO OPERAÇÕES", display: false },
          legend: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    )
  );
};

export default BarChart;
