import React from 'react'
import axios from '../utils/config'
import { saveAs } from 'file-saver'
import { AiOutlineDownload } from 'react-icons/ai'

const DownloadButton = ({ endpointSet }) => {
  const download = async () => {
    const authString = `Bearer ${window.sessionStorage.getItem('token')}`
    const header = {
      headers: {
        Authorization: authString,
      },
    }
    await axios
      .get(`${process.env.REACT_APP_RTM_CORE_URL}/api/${endpointSet}s/bulk/download`, header)
      .then((res) => {
        const fileBlob = new Blob([res.data], {
          type: 'text/csv;charset=utf-8;',
        })
        saveAs(fileBlob, `${endpointSet}s.csv`)
      })
  }

  return (
    <span className="downloadButton" onClick={download}>
      <AiOutlineDownload size={25} style={{ marginRight: '10px' }} /> Arquivo de retorno
    </span>
  )
}

export default DownloadButton
