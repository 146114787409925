import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import UploadModal from "../components/UploadModal";
import { GoCloudUpload } from "react-icons/go";
import { Button } from "react-bootstrap";
import postBulkFile from "../utils/postBulkFile";
import { ClipLoader } from "react-spinners";
import ErrorModal from "./ErrorModal";

const CardFormBulkFile = ({ changeCardTitle }) => {
  const [opType, setOpType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [uploadModal, setUploadModal] = useState(false);
  const [ordersResponse, setOrdersResponse] = useState([]);

  const loaderState = (option) => {
    setIsLoading(option);
  };

  const showErrorModal = () => {
    setErrorObj({
      header: "Erro ao realizar o upload",
      message: "Verifique se as informações do arquivo estão corretas",
    });
    setErrorModal(true);
  };

  const showFormError = () => {
    setErrorObj({
      header: "Erro na requisição solicitada",
      message: "Por favor, preencha todos os campos obrigatórios",
    });
    setErrorModal(true);
  };

  const closeErrorModal = () => {
    setErrorModal(false);
  };

  const handleUploadModal = (option) => {
    setUploadModal(option);
  };

  const postAndShowModal = async () => {
    const fileInput = document.querySelector(".input-file-selector");
    try {
      if (!opType && !fileInput.value) {
        throw Error("Incorrect form field");
      }
      const postResult = await postBulkFile(opType);
      if (postResult) {
        setOrdersResponse(await postResult);
        handleUploadModal(true);
        loaderState(false);
      } else {
        throw Error("Error on bulk upload");
      }
    } catch (error) {
      loaderState(false);
      if (error.message === "Incorrect form field") {
        showFormError();
      } else {
        showErrorModal();
      }
    }
  };

  return (
    <Card>
      <ErrorModal show={errorModal} close={closeErrorModal} error={errorObj} />
      <UploadModal
        show={uploadModal}
        closeUploadModal={handleUploadModal}
        orders={ordersResponse}
        type={opType}
      />
      <Card.Body>
        <Form className="d-flex flex-column card-bulk">
          <Form.Group
            controlId="form.txType"
            className="d-flex flex-column align-items-center"
          >
            <select
              className="form-select application-type-bulk text-center"
              onChange={(e) => {
                changeCardTitle(e.target.value);
                setOpType(e.target.value);
              }}
            >
              <option value="">Selecione tipo</option>
              <option value="subscription">Aplicação</option>
              <option value="redemption">Resgate</option>
            </select>
            <Form.Label className="input-text">Tipo de Operação</Form.Label>
          </Form.Group>
          <Form.Group controlId="form.file">
            <Form.Control
              size="lg"
              type="file"
              className="d-none input-file-selector"
              onChange={(e) => {
                let result;
                e.target.value.includes("/")
                  ? (result = e.target.value.split("/"))
                  : (result = e.target.value.split("\\"));
                const length = result.length;
                setFileName(result[length - 1]);
              }}
              required
            />
            <Form.Label className="choose-file-button shadow-md">
              <GoCloudUpload className="mx-2" /> {fileName || "Pesquisar"}
            </Form.Label>
            <p className="input-file-label input-text">Selecione o Arquivo</p>
          </Form.Group>
          <div className="d-flex justify-content-center">
            {isLoading ? (
              <ClipLoader size={40} color="#bf2c4a" />
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  loaderState(true);
                  try {
                    postAndShowModal();
                  } catch (error) {
                    showErrorModal();
                  }
                }}
              >
                UPLOAD
              </Button>
            )}
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CardFormBulkFile;
