import axios from 'axios'
import { CORE_URL } from './config'

const getSubscriptions = async () => {
  const authString = `Bearer ${window.sessionStorage.getItem('token')}`

  const header = {
    headers: {
      Authorization: authString,
    },
  }

  return await axios.get(`${CORE_URL}/api/subscriptions`, header).then((response) => {
    return response.data
  })
}

export default getSubscriptions
