import React from 'react'
import { Link } from 'react-router-dom'

const IconTile = ({ title, children, href }) => {
  return (
    <div className="tile d-flex flex-column justify-content-between align-items-stretch">
      <Link to={href} className="dark-red">
        <div className="tile-icon flex-shrink-1">{children}</div>
        <div className="tile-text text-white flex-grow-1">{title}</div>
      </Link>
    </div>
  )
}

export default IconTile
