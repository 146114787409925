import React, { useContext, useEffect } from "react";

import { MessageContext } from "../../context";

import * as S from "./styles";

const MessageToast = () => {
  const { listMessage, setMessage } = useContext(MessageContext);

  const deleteMessage = (id) => {
    const cloneListMessage = [...listMessage];
    cloneListMessage.splice(id, 1);
    setMessage(cloneListMessage);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (listMessage.length) {
        const cloneListMessage = [...listMessage];
        cloneListMessage.splice(0, 1);
        setMessage(cloneListMessage);
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [listMessage, setMessage]);

  return (
    <S.Container>
      {listMessage.map((m, i) => {
        return (
          <S.Message type={m.type} key={i}>
            <S.MessageText>{m.text}</S.MessageText>
            <S.MessageClose onClick={() => deleteMessage(i)} />
          </S.Message>
        );
      })}
    </S.Container>
  );
};

export default MessageToast;
