import axios from 'axios'

const CORE_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_RTM_CORE_URL_DEV
    : process.env.REACT_APP_RTM_CORE_URL_UAT

const getRedemptions = async () => {
  const authString = `Bearer ${window.sessionStorage.getItem('token')}`
  const header = {
    headers: {
      Authorization: authString,
    },
  }
  return await axios.get(`${CORE_URL}/api/redemptions`, header).then((response) => {
    return response.data
  })
}

export default getRedemptions
