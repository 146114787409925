import React from 'react'
import Button from 'react-bootstrap/Button'
import putOrder from '../utils/putOrder'

const AcceptButton = ({
  userProvider,
  orderId,
  orderType,
  approveOrder,
  openErrorModal,
  loaderState,
}) => {
  return (
    <Button
      color="primary"
      id="accept-button"
      onClick={async () => {
        try {
          loaderState(true)
          const statusObj = await putOrder(userProvider, orderId, orderType)
          return approveOrder(statusObj)
        } catch (error) {
          console.log('ERRO', error)
          loaderState(false)
          openErrorModal()
        }
      }}
    >
      APROVAR
    </Button>
  )
}

export default AcceptButton
