import React, { createContext, useState } from "react";

const DEFAULT_VALUE = {
  state: {
    nameCompany: "",
    selectedApp: {},
    isLoggedIn: false,
    tokenCompany: "",
    tokenApp: "",
    tokenUser: "",
    tokenConsent: "",
    consentStatus: false,
    modalSignin: false,
    modalSignup: false,
  },
  setState: () => {},
};

const DEFAULT_VALUE_MESSAGES = {
  listMessage: [],
  setMessage: () => {},
};

export const DeveloperContext = createContext(DEFAULT_VALUE);
export const MessageContext = createContext(DEFAULT_VALUE_MESSAGES);

const DeveloperContextProvider = ({ children }) => {
  const [state, setState] = useState(DEFAULT_VALUE.state);

  return (
    <DeveloperContext.Provider value={{ state, setState }}>
      {children}
    </DeveloperContext.Provider>
  );
};

const MessageContextProvider = ({ children }) => {
  const [listMessage, setMessage] = useState(
    DEFAULT_VALUE_MESSAGES.listMessage
  );

  return (
    <MessageContext.Provider value={{ listMessage, setMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

const GlobalContext = ({ children }) => {
  return (
    <MessageContextProvider>
      <DeveloperContextProvider>{children}</DeveloperContextProvider>
    </MessageContextProvider>
  );
};

export default GlobalContext;
