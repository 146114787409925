import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavItem } from 'react-bootstrap'
import { BsBell } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export default function NotificationsAlert({ user }) {
  const [notifications, setNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(0)

  const toggleNotifications = async () => {
    setUnreadNotifications(0)
    await axios.delete(`${process.env.REACT_APP_MVP_FUNDS}/notifications?partyId=${user.cnpj}`)
    if (document.querySelector('.notifications-list')) {
      document.querySelector('.notifications-list').classList.toggle('show')
    }
  }

  useEffect(() => {
    async function fetchNotifications() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_MVP_FUNDS}/notifications?partyId=${user.cnpj}`
        )
        const unRead = res.data.filter((element) => element.read === false)
        setUnreadNotifications(unRead.length)
        setNotifications(res.data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchNotifications()
  }, [user])

  return (
    <NavItem className="px-3 text-red navitem-noborder" onClick={() => toggleNotifications()}>
      <div className="d-flex align-items-center notifications" id="notification-bell">
        {unreadNotifications > 0 && (
          <div className="notification-number d-flex justify-content-center align-items-center">
            {unreadNotifications}
          </div>
        )}
        <BsBell className="mr-3"></BsBell>
        Notificações
      </div>
      <div className="show-notifications">
        <Notification notifications={notifications} />
      </div>
    </NavItem>
  )
}

function Notification({ notifications }) {
  return (
    <div className="notifications-list dropdown-menu p-0">
      <Link to="/portability/dashboard" className="notification-link">
        <ul>
          {notifications &&
            notifications
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((notification, index) => {
                return (
                  <div key={notification + index}>
                    <li
                      className={`notification-item d-flex justify-content-between align-items-center py-2 pl-3 ${
                        notification.read && 'bg-gray'
                      }`}
                      key={`notification-${index}`}>
                      <div className="wrapper">
                        <div className="notification-title text-red">{notification.subject}</div>
                        <div className="notification-body">{notification.message}</div>
                      </div>
                    </li>
                    {notifications.length === index + 1 ? null : <hr className="m-0" />}
                  </div>
                )
              })}
        </ul>
      </Link>
    </div>
  )
}
