import React from 'react'

const TableBodyBunks = ({ order, i, type }) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  let amount
  if (type === 'subscription') {
    amount = order.MltplOrdrDtls.IndvOrdrDtls.AmtOrUnitsOrPctg.GrssAmt.replace(
      ',',
      '.'
    )
  } else {
    amount = order.MltplOrdrDtls.IndvOrdrDtls.AmtOrUnits.GrssAmt.replace(
      ',',
      '.'
    )
  }

  return (
    <>
      <tr className="table-body">
        <td className="td border-right text-center">{`#${i + 1}`}</td>
        <td className="td border-right">
          {order.MltplOrdrDtls.IndvOrdrDtls.FinInstrmDtls.Id}
        </td>
        <td className="td border-right">
          {order.MltplOrdrDtls.IndvOrdrDtls.FinInstrmDtls.Nm}
        </td>
        <td>
          {order.MltplOrdrDtls.IndvOrdrDtls.AdditionalDetails.OrderStatus.toUpperCase()}
        </td>
        <td className="td border-right">{formatter.format(amount)}</td>
        <td className="td text-left">
          {order.MltplOrdrDtls.IndvOrdrDtls.OrdrRef}
        </td>
      </tr>
    </>
  )
}

export default TableBodyBunks
