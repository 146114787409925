import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavbarRtm from '../components/NavbarRtm'
import decodeUserInfo from '../utils/decodeUserInfo'
import PortabilityDashboardLeft from '../components/portability/PortabilityDashboardLeft'
import PortabilityDashboardRight from '../components/portability/PortabilityDashboardRight'
import { Container } from 'react-bootstrap'

export default function PortabilityDashboard() {
  const [notifications, setNotifications] = useState([])
  const [portabilities, setPortabilities] = useState([])
  const [currentPortability, setCurrentPortability] = useState(null)
  const [statusStep, setStatusStep] = useState(0)
  const [forceRender, setForceRender] = useState(false)

  const handleCurrentPortability = (e, portability) => {
    if (e) {
      const listItems = document.querySelectorAll('.dashboard-filter-list > li')
      listItems.forEach((item) => {
        if (item.classList.value.includes('active')) {
          item.classList.remove('active')
        }
      })
      e.target.classList.add('active')
    }

    setCurrentPortability(portability)
    switch (portability.portabilityStatus) {
      case 'OK_TRANSFEROR':
        setStatusStep(1)
        break
      case 'OK_TRANSFEREE':
        setStatusStep(2)
        break
      case 'INFORM_ADMINS':
        setStatusStep(3)
        break
      case 'OK_ADMINS':
        setStatusStep(4)
        break
      case 'FINISH':
        setStatusStep(5)
        break
      default:
        return 0
    }
  }

  useEffect(() => {
    const userToken = window.sessionStorage.getItem('token')
    const { user } = decodeUserInfo(userToken)
    async function fetchNotifications() {
      try {
        const notificationRes = await axios.get(
          `${process.env.REACT_APP_MVP_FUNDS}/notifications?partyId=${user.cnpj}`
        )
        setNotifications(notificationRes.data)
      } catch (error) {
        console.log(error)
      }
    }
    async function fetchPortabilities() {
      const portabilityRes = await axios.get(
        `${process.env.REACT_APP_MVP_FUNDS}/portability?partyId=${user.cnpj}`
      )

      await setPortabilities(portabilityRes.data)

      if (currentPortability) {
        const portability = portabilityRes.data.find((e) => e.id === currentPortability.id)
        setCurrentPortability(portability)
        handleCurrentPortability(null, portability)
      }
    }
    fetchNotifications()
    fetchPortabilities()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRender])

  return (
    <div>
      <NavbarRtm />
      <Container className="portability-dashboard">
        <h1 className="text-red my-5 text-center">Painel de Portabilidade</h1>
        <div className="d-flex">
          <PortabilityDashboardLeft
            portabilities={portabilities}
            handleCurrentPortability={handleCurrentPortability}
          />
          <PortabilityDashboardRight
            notifications={notifications}
            statusStep={statusStep}
            currentPortability={currentPortability}
            forceRender={setForceRender}
          />
        </div>
      </Container>
    </div>
  )
}
