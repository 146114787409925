const DashBoardFilter = ({ metrics, handleFundChange, refresh }) => {
  const cnpjNumber = (cnpj) => {
    if (cnpj.includes(".") || cnpj.includes("-")) {
      return cnpj.replace(/\.|\/|-/g, "");
    }
    return cnpj;
  };

  const activateListItem = (li) => {
    const listItems = document.querySelectorAll(".dashboard-filter-list > li");
    listItems.forEach((item) => {
      if (item.classList.value.includes("active")) {
        item.classList.remove("active");
      }
    });
    li.classList.add("active");
  };

  return (
    <>
      <div className="dashboard-filter">
        <ul className="dashboard-filter-list">
          <li
            className="active"
            onClick={() => {
              refresh();
            }}
          >
            Métricas operações consolidadas
          </li>
          {metrics.cnpj.map((fund, i) => {
            return (
              <li
                key={`fund${i}`}
                onClick={(e) => {
                  activateListItem(e.target);
                  handleFundChange(cnpjNumber(fund));
                }}
              >
                {`${metrics.funds[i]}`}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default DashBoardFilter;
