import { IoWarningOutline } from 'react-icons/io5'

const ErrorField = ({ errors, touched, input }) => {
  return errors[input] && touched[input] ? (
    <div className="d-flex align-items-center validation-error-message justify-content-right shadow-md">
      <IoWarningOutline className="mr-1" />
      {errors[input]}
    </div>
  ) : null
}

export default ErrorField
