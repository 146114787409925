import React, { useEffect, createRef } from 'react'
import Modal from './Modal'
import InputRadio from './InputRadio'
import FundInfo from './FundInfo'
import ErrorModal from './ErrorModal'
import { Form, Button, Col } from 'react-bootstrap'
import { AiOutlineSearch } from 'react-icons/ai'
import { BiRefresh } from 'react-icons/bi'
import { useState } from 'react'
import axios from '../utils/config'
import ClipLoader from 'react-spinners/ClipLoader'
import { Formik } from 'formik'
import * as Yup from 'yup'
import NumberFormat from 'react-number-format'
import MaskedInput from 'react-input-mask'

const BootstrapForm = ({ onModalClose, changeCardTitle }) => {
  const [error, setError] = useState({})
  const formTag = createRef()
  const inputCNPJ = createRef()
  const [loading, setLoading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [fund, setFund] = useState({})
  const [valueInput, setValueInput] = useState()
  const [valuesForm, setValuesForm] = useState({
    cnpj: '',
  })
  const [modalInfo, setModalInfo] = useState({})
  const [opType, setOpType] = useState('')
  const [getFundValidate, setFundValidate] = useState(false)

  const handleInput = (e) => {
    setValuesForm((values) => {
      return { ...values, [e.target.name]: e.target.value }
    })
  }

  useEffect(() => {
    if (modalInfo?.cnpjFundo?.match(/\.|-/g)) {
      modalInfo.cnpjFundo = modalInfo.cnpjFundo.replace(/\.|-|\//g, '')
    }
  }, [modalInfo])

  const onInputChange = (e) => {
    if (opType === 'Aplicação') {
      setModalInfo({ ...modalInfo, firstTime: e.target.value })
    } else {
      setModalInfo({
        ...modalInfo,
        firstTime: false,
        partialRedemption: e.target.value,
      })
    }
  }

  useEffect(() => {
    if (valuesForm.cnpj) {
      inputCNPJ.current.focus()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesForm.cnpj])

  const handleErrorModalShow = () => {
    setShowErrorModal(true)
    setLoading(false)
  }

  const handleErrorModalClose = () => {
    setShowErrorModal(false)
  }

  const getFundData = async () => {
    const cnpj = modalInfo.cnpjFundo
    const authString = `Bearer ${window.sessionStorage.getItem('token')}`
    const header = {
      headers: {
        Authorization: authString,
      },
    }
    try {
      await axios
        .get(`${process.env.REACT_APP_RTM_CORE_URL}/api/funds/${cnpj}`, header)
        .then((res) => {
          const fundInfo = res.data
          setFund({ infos: fundInfo })
          setFundValidate(true)
        })
    } catch (error) {
      setError({
        header: 'Erro ao pesquisar fundo de investimento',
        message: 'Fundo não encontrado! Se o problema persistir, tente novamente mais tarde.',
      })
      handleErrorModalShow()
      return error
    }
  }

  const loader = async () => {
    try {
      setLoading(true)
      await validateForm(modalInfo)
      return true
    } catch (error) {
      console.log(error.message)
      setError({ header: 'Erro ao submeter operação', message: error.message })
      handleErrorModalShow()
      return false
    }
  }

  const loaderSearch = async () => {
    try {
      setLoading(true)
      await validateSearch(modalInfo)
      setTimeout(getFundData, 2000)
    } catch (error) {
      console.log(error.message)
      setError({ header: 'Erro ao submeter operação', message: error.message })
      handleErrorModalShow()
    }
  }

  const updateModalAndCardTitle = (value) => {
    changeCardTitle(value)
    setModalInfo({ ...modalInfo, transactionType: value })
    switch (value) {
      case 'subscription':
        setOpType('Aplicação')
        break

      case 'redemption':
        setOpType('Resgate')
        break

      default:
        setOpType('Operação')
        break
    }
  }

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required('Valor é um campo obrigatório'),
    cnpjFundo: Yup.string().required('CNPJ do Fundo/ Código ANBIMA é um campo obrigatório'),
    firstTime: Yup.string().required('Campo obrigatório não preenchido'),
    investor: Yup.string().required('Investidor é um campo obrigatório'),
    transactionType: Yup.string()
      .oneOf(['subscription', 'redemption'], 'Selecione Aplicação ou Resgate')
      .required('Selecione Aplicação ou Resgate'),
  })

  const validationSchemaSearch = Yup.object().shape({
    cnpjFundo: Yup.string().required('CNPJ do Fundo/ Código ANBIMA é um campo obrigatório'),
  })

  const validateForm = async (formValues) => {
    return await validationSchema.validate(formValues)
  }

  const validateSearch = async (formValues) => {
    return await validationSchemaSearch.validate(formValues)
  }

  const sanitizeValue = (string) => {
    return parseFloat(string.replace('R$ ', '').replace('.', '').replace(',', '.'))
  }

  const resetForm = () => {
    formTag.current.reset()
    inputCNPJ.current.value = ''
    setValueInput(0)
    setFund({})
    setFundValidate(false)
    setLoading(false)
  }

  return (
    <>
      <Formik
        initialValues={{
          amount: '',
          cnpjFundo: '',
          firstTime: '',
          investor: '',
          transactionType: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          console.log(values)
          resetForm()
        }}
        onChange={async (values) => {
          console.log(values)
        }}
        validationSchema={validationSchema}>
        <Form className="d-flex" ref={formTag}>
          <ErrorModal error={error} show={showErrorModal} close={handleErrorModalClose} />
          <div className="left-form">
            <Form.Group controlId="form.txType">
              <Form.Label className="input-text pb-3">Tipo de Operação*</Form.Label>
              <select
                id="application-type"
                className="form-select"
                onChange={(e) => {
                  updateModalAndCardTitle(e.target.value)
                }}>
                <option value="default">Selecione tipo</option>
                <option value="subscription">Aplicação</option>
                <option value="redemption">Resgate</option>
              </select>
            </Form.Group>
            <Form.Group controlId="form.investor">
              <Form.Label className="input-text">Investidor *</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="investidor"
                placeholder="Nome/Código do Investidor"
                onChange={(e) => {
                  setModalInfo({ ...modalInfo, investor: e.target.value })
                }}
              />
            </Form.Group>
            {opType === 'Aplicação' ? (
              <Form.Group controlId="form.firstAplication">
                <Form.Label className="input-text mr-4">Primeira Aplicação? *</Form.Label>
                <InputRadio
                  name="aplicacao"
                  className="form-font-radio"
                  onInputChange={onInputChange}
                  opType={opType}
                />
              </Form.Group>
            ) : opType === 'Resgate' ? (
              <Form.Group controlId="form.firstAplication">
                <Form.Label className="input-text mr-4">Tipo de Resgate *</Form.Label>
                <InputRadio
                  name="resgate"
                  className="form-font-radio"
                  onInputChange={onInputChange}
                  opType={opType}
                />
              </Form.Group>
            ) : (
              false
            )}
            <Form.Group controlId="form.value">
              <Form.Label className="input-text">
                Valor d{opType === 'Resgate' ? 'o' : 'a'} {opType ? opType : 'Operação'} *
              </Form.Label>
              <br></br>
              <NumberFormat
                thousandsGroupStyle="thousand"
                prefix="R$ "
                decimalSeparator=","
                displayType="input"
                type="text"
                name="valor"
                thousandSeparator="."
                allowNegative={false}
                fixedDecimalScale={true}
                allowLeadingZeros={false}
                isNumericString={false}
                allowEmptyFormatting={false}
                className="form-control form-control-lg"
                value={valueInput}
                onChange={(e) => {
                  setValueInput(e.target.value)
                  setModalInfo({
                    ...modalInfo,
                    amount: sanitizeValue(e.target.value),
                  })
                }}
              />
            </Form.Group>

            {getFundValidate && (
              <>
                <h2 className="orders-title mt-5 mb-4">Dados para Liquidação</h2>
                <div className="d-flex .d-inline-flex mb-3">
                  <Col md={6} className="pl-0 pr-3">
                    <Form.Label className="input-text mb-2">Tipo Liquidação</Form.Label>
                    <select
                      type="text"
                      style={{ width: '100%', fontSize: '1.2rem' }}
                      id="ref"
                      className="form-select"
                      onChange={(event) => {
                        setModalInfo((e) => {
                          return {
                            ...e,
                            [event.target.name]: event.target.value,
                          }
                        })
                      }}
                      name="ref"
                      placeholder="Somente números"
                      defaultValue="">
                      <option value="">Selecione</option>
                      <option value="TED">TED</option>
                      <option value="B3">B3</option>
                    </select>
                  </Col>
                  <Col md={6} className="pr-0">
                    <Form.Label className="input-text mb-2">Tipo Conta</Form.Label>
                    <select
                      id="cd"
                      style={{ width: '100%', fontSize: '1.2rem' }}
                      className="form-select"
                      onChange={(event) => {
                        setModalInfo((e) => {
                          return {
                            ...e,
                            [event.target.name]: event.target.value,
                          }
                        })
                      }}
                      name="cd"
                      defaultValue="">
                      <option value="">Selecione</option>
                      <option value="CC">Conta Corrente</option>
                      <option value="CD">Conta Depósito</option>
                    </select>
                  </Col>
                </div>
                <Form.Group>
                  <Form.Label className="input-text">Banco</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    id="clr"
                    name="clr"
                    placeholder="Somente números"
                    onChange={(event) => {
                      setModalInfo((e) => {
                        return {
                          ...e,
                          [event.target.name]: event.target.value,
                        }
                      })
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="input-text">Agência</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    id="issr"
                    onChange={(event) => {
                      setModalInfo((e) => {
                        return {
                          ...e,
                          [event.target.name]: event.target.value,
                        }
                      })
                    }}
                    name="issr"
                    placeholder="Somente números"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="input-text">Conta + Digito Verificador</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    id="idd"
                    onChange={(event) => {
                      setModalInfo((e) => {
                        return {
                          ...e,
                          [event.target.name]: event.target.value,
                        }
                      })
                    }}
                    name="idd"
                    placeholder="Somente números"
                  />
                </Form.Group>
              </>
            )}

            <div className="mt-5">
              <p className="form-footer">*Campos Obrigatórios</p>
            </div>
          </div>
          <div className="right-form">
            <Form.Group>
              <Form.Label className="input-text">CNPJ do Fundo ou Código ANBIMA *</Form.Label>
              {valuesForm.cnpj?.replace(/_|-|\/|\./g, '').length > 6 ? (
                <MaskedInput
                  mask="99.999.999/9999-99"
                  placeholder="Somente números"
                  id="cnpj"
                  name="cnpj"
                  value={valuesForm.cnpj}
                  onChange={(e) => {
                    handleInput(e)
                    setModalInfo({ ...modalInfo, cnpjFundo: e.target.value })
                  }}>
                  {(inputProps) => (
                    <Form.Control ref={inputCNPJ} {...inputProps} size="lg" type="text" />
                  )}
                </MaskedInput>
              ) : (
                <Form.Control
                  placeholder="Somente números"
                  id="cnpj"
                  name="cnpj"
                  size="lg"
                  ref={inputCNPJ}
                  value={valuesForm.cnpj?.replace(/_|-|\/|\./g, '') ?? ''}
                  onChange={(e) => {
                    handleInput(e)
                    setModalInfo({ ...modalInfo, cnpjFundo: e.target.value })
                  }}
                />
              )}

              {fund.infos?.nomeFantasia ? (
                <div className="d-flex justify-content-end">
                  <BiRefresh
                    size="2rem"
                    color="#c53048"
                    onClick={async () => await getFundData()}
                    className="refresh"
                  />
                </div>
              ) : (
                <></>
              )}
            </Form.Group>
            {!fund.infos?.nomeFantasia ? (
              <>
                <Button
                  variant="primary text-uppercase"
                  onClick={async () => loaderSearch()}
                  id="btn-search"
                  className="d-flex align-items-center justify-content-center">
                  <AiOutlineSearch size="1.3em" className="mr-1" />{' '}
                  <span className="ml-1 mb-1">Pesquisar</span>
                </Button>
                {loading ? (
                  <div id="spinner">
                    <ClipLoader size={150} color="#bf2c4a" />
                  </div>
                ) : (
                  false
                )}
              </>
            ) : (
              <>
                <FundInfo fund={fund} />
                <Modal
                  txInfo={modalInfo}
                  fund={fund.infos}
                  onModalClose={onModalClose}
                  handleSuccess={loader}
                  resetForm={resetForm}
                />
              </>
            )}
          </div>
        </Form>
      </Formik>
    </>
  )
}

export default BootstrapForm
