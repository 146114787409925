import axios from 'axios'
import capitalizeString from './capitalizeString'
import { CORE_URL } from './config'

const putOrder = async (userProvider, orderId, orderType) => {
  let capitalizedProvider = ''
  switch (userProvider) {
    case 'administrador':
      capitalizedProvider = 'Admin'
      break
    case 'gestor':
      capitalizedProvider = 'Manager'
      break
    case 'custodiante':
      capitalizedProvider = 'Custodian'
      break

    default:
      break
  }

  const authString = `Bearer ${window.sessionStorage.getItem('token')}`
  const headers = {
    headers: {
      provider: capitalizedProvider,
      Authorization: authString,
    },
  }

  const data = {}

  try {
    const response = await axios.put(`${CORE_URL}/api/${orderType}/${orderId}`, data, headers)
    const addInfo = response.data.data.MltplOrdrDtls.IndvOrdrDtls.AdditionalDetails

    return {
      order: capitalizeString(addInfo.OrderStatus),
      admin: capitalizeString(addInfo.Providers.Admin.Status),
      custodian: capitalizeString(addInfo.Providers.Custodian.Status),
      manager: capitalizeString(addInfo.Providers.Manager.Status),
    }
  } catch (error) {
    console.log(error)
    return error
  }
}

export default putOrder
