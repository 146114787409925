import React from 'react'
import { Table } from 'react-bootstrap'
import sanitizeCNPJ from '../../utils/sanitizeCNPJ'

export default function PartyIdentificationsTable({ data, user }) {
  return (
    <Table className="my-4 orders-table" size="md">
      <thead>
        <tr>
          <th className="text-red text-center bg-gray" colSpan="3">
            Cedente
          </th>
          <th className="text-red text-center bg-gray" colSpan="3">
            Cessionário
          </th>
        </tr>
        <tr>
          <th className="text-red text-center">Razão Social</th>
          <th className="text-red text-center">CNPJ</th>
          <th className="text-red text-center">Conta Investidor</th>
          <th className="text-red text-center bl-gray">Razão Social</th>
          <th className="text-red text-center">CNPJ</th>
          <th className="text-red text-center">Conta Investidor</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center">{user?.contactName}</td>
          <td className="text-center">{sanitizeCNPJ(data?.fromParty)}</td>
          <td className="text-center">{data?.investorFromPartyAccount}</td>
          <td className="text-center bl-gray">{data?.toPartyName}</td>
          <td className="text-center">{sanitizeCNPJ(data?.toParty)}</td>
          <td className="text-center">{data?.investorToPartyAccount}</td>
        </tr>
      </tbody>
    </Table>
  )
}
