import React, { useContext, useState } from 'react'
import { Dropdown, DropdownButton, Form } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import { ClipLoader } from 'react-spinners'
import MaskedInput from 'react-input-mask'
import ErrorModal from '../ErrorModal'
import axios from 'axios'
import sanitizeCNPJ from '../../utils/sanitizeCNPJ'
import decodeUserInfo from '../../utils/decodeUserInfo'
import { FaSearch } from 'react-icons/fa'
import postPortabilityFile from '../../utils/postPortabilityFile'
import { MessageContext } from '../../context'
import message from '../../utils/message'
import { useHistory } from 'react-router'
import ANBIMAFileUpload from './ANBIMAFileUpload'
import SinqiaFileUpload from './SinqiaFileUpload'

const PortabilityBulkFile = ({ changeCardTitle }) => {
  const userToken = window.sessionStorage.getItem('token')
  const { listMessage, setMessage } = useContext(MessageContext)
  const { user } = decodeUserInfo(userToken)
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState({})
  const [fileStandard, setFileStandard] = useState(null)
  const [errorModal, setErrorModal] = useState(false)
  const [errorObj, setErrorObj] = useState({})
  const [transferee, setTransferee] = useState(null)
  const [searchLoading, setSearchLoading] = useState(false)
  const history = useHistory()
  const [portabilityData, setPortabilityData] = useState({
    fromParty: user?.cnpj,
    fromPartyName: user?.contactName,
  })

  const searchTransferee = async (cnpj) => {
    setSearchLoading(true)
    try {
      const cnpjJustNumbers = cnpj.replace(/\.|-|\//g, '')
      const authString = `Bearer ${window.sessionStorage.getItem('token')}`
      const header = {
        headers: {
          Authorization: authString,
        },
      }
      const result = await axios.get(
        `${process.env.REACT_APP_RTM_CORE_URL}/api/user/cnpj/${cnpjJustNumbers}`,
        header
      )
      const transferee = result.data.filter((record) => record?.email?.includes('dist'))[0]
      setTransferee(transferee)
      setPortabilityData((values) => {
        return { ...values, toPartyName: transferee?.contactName }
      })
      setSearchLoading(false)
    } catch (error) {
      console.log(error)
      setTransferee('PARTICIPANTE NÃO ENCONTRADO')
      setSearchLoading(false)
    }
  }

  const loaderState = (option) => {
    setIsLoading(option)
  }

  const showErrorModal = () => {
    setErrorObj({
      header: 'Erro ao realizar o upload',
      message: 'Verifique se as informações do arquivo estão corretas',
    })
    setErrorModal(true)
  }

  const showFormError = () => {
    setErrorObj({
      header: 'Erro na requisição solicitada',
      message: 'Por favor, preencha todos os campos obrigatórios',
    })
    setErrorModal(true)
  }

  const closeErrorModal = () => {
    setErrorModal(false)
  }

  const handleInput = (e) => {
    setPortabilityData((values) => {
      if (e.target.value.includes('/')) {
        return { ...values, [e.target.name]: e.target.value.replace(/\D+/g, '') }
      }
      return { ...values, [e.target.name]: e.target.value }
    })
  }

  const postAndShowModal = async () => {
    const files = document.querySelectorAll('.input-file-selector')

    try {
      files.forEach((file) => {
        if (!file.value) {
          throw Error('Incorrect form field')
        }
      })
      const postResult = await postPortabilityFile(files, portabilityData, fileStandard)
      if (postResult) {
        message('Portabilidade atualizada com sucesso', listMessage, setMessage, 'success')
        history.push('/')
        loaderState(false)
      } else {
        throw Error('Error on bulk upload')
      }
    } catch (error) {
      console.log(error)
      loaderState(false)
      if (error.message === 'Incorrect form field') {
        showFormError()
      } else {
        showErrorModal()
      }
    }
  }

  const ChooseFileUpload = () => {
    return (
      <div id="select-file" className="text-center choose-standard">
        <h5>Escolha o padrão de arquivo de portabilidade</h5>
        <br />
        <DropdownButton id="dropdown-file-option" title="Escolha um padrão">
          <Dropdown.Item
            className="file-option"
            onClick={() => {
              setFileStandard('anbima')
            }}>
            Arquivo ANBIMA 2021
          </Dropdown.Item>
          <Dropdown.Item
            className="file-option"
            onClick={() => {
              setFileStandard('sinqia')
            }}>
            Arquivo SINQIA
          </Dropdown.Item>
        </DropdownButton>
      </div>
    )
  }

  const renderFileUpload = (fileStandard) => {
    switch (fileStandard) {
      case 'anbima':
        return (
          <ANBIMAFileUpload
            setFileName={setFileName}
            fileName={fileName}
            loaderState={loaderState}
            isLoading={isLoading}
            postAndShowModal={postAndShowModal}
            showErrorModal={showErrorModal}
            setFileStandard={setFileStandard}
          />
        )
      case 'sinqia':
        return (
          <SinqiaFileUpload
            setFileName={setFileName}
            fileName={fileName}
            loaderState={loaderState}
            isLoading={isLoading}
            postAndShowModal={postAndShowModal}
            showErrorModal={showErrorModal}
            setFileStandard={setFileStandard}
          />
        )

      default:
        return <ChooseFileUpload />
    }
  }

  return (
    <Card>
      <ErrorModal show={errorModal} close={closeErrorModal} error={errorObj} />
      <Card.Body className="d-flex justify-content-between align-items-center">
        <div className="card-left">
          <h4 className="text-red portability-party">
            <em>Informações Cedente</em>
          </h4>
          <hr className="portability-form-divider" />
          <div className="d-flex justify-content-between">
            <Form.Group className="mb-2 bulk-port" controlId="fromParty">
              <Form.Label className="text-red mb-2">CNPJ</Form.Label>
              <Form.Control
                type="text"
                placeholder="CNPJ Cedente"
                value={user?.cnpj && sanitizeCNPJ(user.cnpj)}
                readOnly={true}
              />
            </Form.Group>
            <Form.Group className="mb-2 bulk-port" controlId="fromPartyName">
              <Form.Label className="text-red mb-2">Razão Social</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome Cedente"
                value={user?.contactName.toUpperCase() && user.contactName.toUpperCase()}
                readOnly={true}
              />
            </Form.Group>
            <Form.Group className="mb-2 bulk-port" controlId="investorFromPartyAccount">
              <Form.Label className="text-red mb-2">Conta Investidor</Form.Label>
              <Form.Control
                type="text"
                placeholder="Conta no Cedente"
                name="investorFromPartyAccount"
                value={portabilityData?.investorFromPartyAccount || ''}
                onChange={(e) => {
                  handleInput(e)
                }}
              />
            </Form.Group>
          </div>

          <h4 className="text-red portability-party">
            <em>Informações Cessionário</em>
          </h4>
          <hr className="portability-form-divider" />
          <div className="d-flex justify-content-between align-items-end mt-3">
            <Form.Group className="mb-2 bulk-port" controlId="toParty">
              <Form.Label className="text-red mb-2">CNPJ</Form.Label> <br />
              <div className="d-flex align-items-center">
                <MaskedInput
                  name="toParty"
                  className="form-control portability-input transferee"
                  mask="99.999.999/9999-99"
                  type="text"
                  placeholder="CNPJ Cessionário"
                  value={portabilityData?.toParty || ''}
                  onChange={(e) => {
                    handleInput(e)
                  }}
                />
                {searchLoading ? (
                  <ClipLoader className="ml-2 shadow text-red" color={'#c53048'} size={20} />
                ) : (
                  <FaSearch
                    className="ml-2 shadow"
                    onClick={() => searchTransferee(document.querySelector('.transferee').value)}
                    id="search-icon"
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-2 bulk-port" controlId="formBasicEmail">
              <Form.Label className="text-red mb-2">Razão Social</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome Cessionário"
                value={transferee?.contactName?.toUpperCase() ?? ''}
                readOnly={true}
              />
            </Form.Group>
            <Form.Group className="mb-2 bulk-port" controlId="investorToPartyAccount">
              <Form.Label className="text-red mb-2">Conta Investidor</Form.Label>
              <Form.Control
                type="text"
                placeholder="Conta no Cessionário"
                name="investorToPartyAccount"
                value={portabilityData?.investorToPartyAccount || ''}
                onChange={(e) => {
                  handleInput(e)
                }}
              />
            </Form.Group>
          </div>
        </div>
        <div className="card-right flex-grow-1 ml-3">{renderFileUpload(fileStandard)}</div>
      </Card.Body>
    </Card>
  )
}

export default PortabilityBulkFile
