import axios from 'axios'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { AiOutlineRight } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'
import MaskedInput from 'react-input-mask'
import { ClipLoader } from 'react-spinners'
import sanitizeCNPJ from '../../utils/sanitizeCNPJ'

export default function PortabilityForm({
  user,
  investorData,
  setInvestorData,
  portabilityData,
  setPortabilityData,
  formStep,
  setFormStep,
}) {
  const [transferee, setTransferee] = useState(null)
  const [searchLoading, setSearchLoading] = useState(false)

  const searchTransferee = async (cnpj) => {
    setSearchLoading(true)
    try {
      const cnpjJustNumbers = cnpj.replace(/\.|-|\//g, '')
      const authString = `Bearer ${window.sessionStorage.getItem('token')}`
      const header = {
        headers: {
          Authorization: authString,
        },
      }
      const result = await axios.get(
        `${process.env.REACT_APP_RTM_CORE_URL}/api/user/cnpj/${cnpjJustNumbers}`,
        header
      )
      const transferee = result.data.filter((record) => record?.email?.includes('dist'))[0]
      setTransferee(transferee)
      setPortabilityData((values) => {
        return { ...values, toPartyName: transferee?.contactName }
      })
      setSearchLoading(false)
    } catch (error) {
      console.log(error)
      setTransferee('PARTICIPANTE NÃO ENCONTRADO')
      setSearchLoading(false)
    }
  }

  const handleUserInput = (e) => {
    setInvestorData((values) => {
      return { ...values, [e.target.name]: e.target.value }
    })
  }

  const handleInput = (e) => {
    setPortabilityData((values) => {
      if (e.target.value.includes('/')) {
        return { ...values, [e.target.name]: e.target.value.replace(/\D+/g, '') }
      }
      return { ...values, [e.target.name]: e.target.value }
    })
  }

  return (
    <>
      <div className="text-center">
        <h1 className="text-red text-center">Solicitação de Portabilidade</h1>
        <small className="text-red mb-5 text-center">
          <strong>*</strong>{' '}
          <em>
            Informações sensíveis dos investidores só serão compartilhadas entre Cedente e
            Cessionário.
          </em>
        </small>
      </div>
      <div className="step0">
        <h4 className="text-red portability-party">
          <em>Informações Cedente</em>
        </h4>
        <hr className="portability-form-divider" />
        <div className="d-flex justify-content-between my-3">
          <Form.Group className="mb-3" controlId="activeCnpj">
            <Form.Label>CNPJ</Form.Label>
            <Form.Control
              type="text"
              placeholder="CNPJ Cedente"
              value={user?.cnpj && sanitizeCNPJ(user.cnpj)}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Razão Social</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome Cedente"
              value={user?.contactName.toUpperCase() && user.contactName.toUpperCase()}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="investorFromPartyAccount">
            <Form.Label>Conta Investidor</Form.Label>
            <Form.Control
              type="text"
              placeholder="Identificação da conta no cedente"
              name="investorFromPartyAccount"
              value={portabilityData.investorFromPartyAccount || ''}
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
        </div>

        <h4 className="text-red portability-party">
          <em>Informações Cessionário</em>
        </h4>
        <hr className="portability-form-divider" />
        <div className="d-flex justify-content-between mt-3">
          <Form.Group className="mb-3" controlId="toParty">
            <Form.Label>CNPJ</Form.Label> <br />
            <div className="d-flex align-items-center">
              <MaskedInput
                name="toParty"
                className="form-control portability-input transferee"
                mask="99.999.999/9999-99"
                type="text"
                placeholder="CNPJ Cessionário"
                value={portabilityData.toParty || ''}
                onChange={(e) => {
                  handleInput(e)
                }}
              />
              {searchLoading ? (
                <ClipLoader className="ml-2 shadow text-red" color={'#c53048'} size={20} />
              ) : (
                <FaSearch
                  className="ml-2 shadow"
                  onClick={() => searchTransferee(document.querySelector('.transferee').value)}
                  id="search-icon"
                />
              )}
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Razão Social</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome Cessionário"
              value={transferee?.contactName?.toUpperCase() ?? ''}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="investorToPartyAccount">
            <Form.Label>Conta Investidor</Form.Label>
            <Form.Control
              type="text"
              placeholder="Identificação da conta no cessionário"
              name="investorToPartyAccount"
              value={portabilityData.investorToPartyAccount || ''}
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </Form.Group>
        </div>

        <h4 className="text-red portability-party">
          <em>Informações Cotista</em>
        </h4>
        <hr className="portability-form-divider" />
        <div className="d-flex justify-content-between mt-3">
          <Form.Group className="col mr-4 p-0" controlId="fullName">
            <Form.Label>
              Nome Completo <strong>*</strong>
            </Form.Label>
            <Form.Control
              name="fullName"
              type="text"
              placeholder="Nome do investidor"
              value={investorData.fullName || ''}
              onChange={(e) => {
                handleUserInput(e)
              }}
            />
          </Form.Group>
          {/* <Form.Group className="col mx-2 p-0" controlId="sinacorCode">
            <Form.Label>Código Investidor (SINACOR)</Form.Label> <br />
            <Form.Control
              name="sinacorCode"
              type="text"
              placeholder="Código SINACOR"
              value={investorData.sinacorCode || ''}
              onChange={(e) => {
                handleUserInput(e)
              }}
            />
          </Form.Group> */}
          <Form.Group className="col ml-4 p-0" controlId="cpfCgc">
            <Form.Label>
              CPF / CGC <strong>*</strong>
            </Form.Label>
            <br />
            <Form.Control
              name="cpfCgc"
              type="text"
              placeholder="Somente números"
              value={investorData.cpfCgc || ''}
              onChange={(e) => {
                handleUserInput(e)
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="d-flex justify-content-center col-5 m-auto">
        {/* <Button
          variant="secondary"
          onClick={() => {
            window.location.href = '/portability/upload'
          }}
          className="d-flex align-items-center justify-content-center m-4 portability-btn">
          <AiOutlineCloudUpload className="mr-2" />
          Upload de arquivo
        </Button> */}
        <Button
          variant="primary"
          onClick={() => {
            setFormStep(formStep + 1)
          }}
          className="d-flex align-items-center justify-content-center m-4 portability-btn">
          {formStep === 0 ? 'Inciar Solicitação' : 'Avançar'} <AiOutlineRight className="ml-2" />
        </Button>
      </div>
    </>
  )
}
