import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import NavbarRtm from "../components/NavbarRtm";
import Card from "react-bootstrap/Card";
import RtmBackground from "../assets/RTM-Elements_back-element.svg";
import CardForm from "../components/Card";
import FadeBackground from "../components/FadeBackground";
import { PulseLoader } from "react-spinners";
import RtmLogo from "../assets/RTM-Elements_logo.svg";

import { MessageContext } from "../context";
import message from "../utils/message";

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState("");
  const { listMessage, setMessage } = useContext(MessageContext);

  const setOrderToValue = (value) => {
    switch (value) {
      case "subscription":
        setOrderType("Aplicação");
        break;
      case "redemption":
        setOrderType("Resgate");
        break;
      default:
        setOrderType("");
        break;
    }
  };

  const onModalClose = () => {
    setLoading(true);
  };

  const LoadingRedirect = () => {
    const [validated] = useState(false);

    setLoading(false);

    message(
      "Boleta registrada com sucesso",
      listMessage,
      setMessage,
      "success"
    );

    return validated ? <Redirect to="/orders" /> : <></>;
  };

  return (
    <>
      <div>
        <NavbarRtm />
        <div className="row">
          <div className="offset-sm-8 col-sm-4">
            <Card.Title>{orderType}</Card.Title>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <CardForm
              onModalClose={onModalClose}
              changeCardTitle={setOrderToValue}
            />
          </div>
        </div>
        <div className="row">
          <div className="offset-sm-8 col-sm-4">
            <img
              className="rtm-background"
              src={RtmBackground}
              alt="Imagem personalizada para o Background"
            />
          </div>
        </div>
        <div className="col-sm-12 rtm-background-gray"></div>
      </div>

      {loading ? (
        <>
          <LoadingRedirect />
          <FadeBackground>
            <img
              className="rtm-logo m-5"
              src={RtmLogo}
              alt="Logo Institucional"
            />
            <PulseLoader size="30px" color="#c53048" />
          </FadeBackground>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default Subscription;
