import React from "react";
import Table from "react-bootstrap/Table";

const TableOrderStatus = ({ metrics, title, fundMetrics }) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return (
    metrics && (
      <div className="table-dashboard">
        {title}
        <Table>
          <thead className="thead-dashboard">
            <tr>
              <th colSpan="3" className="text-center">
                <em>Métricas por status da operação</em>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Qtde</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody className="tbody-dashboard">
            <tr>
              <td>Confirmadas</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.orderStatus.confirmed.operations}</td>
                  <td>
                    {formatter.format(fundMetrics.orderStatus.confirmed.amount)}
                  </td>
                </>
              ) : (
                <>
                  <td>{metrics.orderStatus.confirmed.operations}</td>
                  <td>
                    {formatter.format(metrics.orderStatus.confirmed.amount)}
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td>Em Processamento</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.orderStatus.processing.operations}</td>
                  <td>
                    {formatter.format(
                      fundMetrics.orderStatus.processing.amount
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td>{metrics.orderStatus.processing.operations}</td>
                  <td>
                    {formatter.format(metrics.orderStatus.processing.amount)}
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td>Rejeitadas</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.orderStatus.rejected.operations}</td>
                  <td>
                    {formatter.format(fundMetrics.orderStatus.rejected.amount)}
                  </td>
                </>
              ) : (
                <>
                  <td>{metrics.orderStatus.rejected.operations}</td>
                  <td>
                    {formatter.format(metrics.orderStatus.rejected.amount)}
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td>Finalizadas</td>
              {fundMetrics ? (
                <>
                  <td>{fundMetrics.orderStatus.closed.operations}</td>
                  <td>
                    {formatter.format(fundMetrics.orderStatus.closed.amount)}
                  </td>
                </>
              ) : (
                <>
                  <td>{metrics.orderStatus.closed.operations}</td>
                  <td>{formatter.format(metrics.orderStatus.closed.amount)}</td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
      </div>
    )
  );
};

export default TableOrderStatus;
