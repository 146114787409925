import axios from "../utils/config";

const postSubscription = async (txData, fund) => {
  if (txData.cnpjFundo.length === 6) {
    txData.cnpjFundo = fund.cnpjFundo
      .replaceAll(".", "")
      .replaceAll("/", "")
      .replaceAll("-", "");
  }

  const data = {
    MsgId: {
      Id: "setr.010",
      CreDtTm: `${new Date()}`,
    },
    MltplOrdrDtls: {
      MstrRef: "MSTRREF123456",
      OrdrDtTm: `${new Date()}`,
      ReqdFutrTradDt: `${new Date(Date.now() + Math.random() * 1000000000)}`,
      AcctId: "12345",
      BnfcryDtls: {
        Nm: "XPTOPLACEHOLDER",
        Id: `${txData.investor}`,
      },
      IndvOrdrDtls: {
        OrdrRef: "REF12345",
        OrdrTp: {
          Id: "A",
        },
        FinInstrmDtls: {
          Id: `${txData.cnpjFundo}`,
          Nm: `${fund.nomeFantasia}`,
        },
        AmtOrUnitsOrPctg: {
          UnitsNb: 123,
          GrssAmt: `${txData.amount}`,
        },
        CshSttlmDtls: {
          Ref: txData.ref,
          ClrSysMmbId: txData.clr,
          CdtrAcct: {
            Id: txData.idd,
            Issr: txData.issr,
            Cd: txData.cd,
          },
        },
        AdditionalDetails: {
          FirstSubscription: txData.firstTime === "true" ? true : false,
        },
      },
    },
  };

  const authString = `Bearer ${window.sessionStorage.getItem("token")}`;
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authString,
    },
  };

  await axios
    .post(`/api/subscriptions`, data, headers)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default postSubscription;
