import jwt_decode from 'jwt-decode'
import { expiredSession } from './auth'

const decodeUserInfo = (token) => {
  try {
    const user = jwt_decode(token)
    return user
  } catch (e) {
    console.log(e)
    const error = expiredSession()
    return { error }
  }
}

export default decodeUserInfo
